import {React, useState} from 'react';
import { Button } from 'react-materialize';
import { postFetch } from '../../Services/HttpUtil';
import CustomAccordion from '../../Components/CustomAccordion';
import {useAuth} from "react-oidc-context";

const MetadataServices = () => {
    const auth = useAuth();

    const [ isRetrieving, setIsRetrieving ] = useState(false);
    const [ queryParams, setQueryParams ] = useState('');
    const [ , setRowCount ] = useState(0);
    const [ expanded, setExpanded ] = useState(false);
    const [ queryParamMetadataRows, setQueryParamMetadataRows ] = useState([]);
    const [ currentOffset, ] = useState(0);
    const [ , setMetadataByQueryParamsLoading ] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const loadMetadata = async () => {
        setIsRetrieving(true);
        try {
            console.log(queryParams);
            console.log(queryParams && JSON.parse(queryParams));
            setMetadataByQueryParamsLoading(true);
            const query = {
                queryAttributes: [],
                offset: currentOffset,
                pageSize: 10,
                queryFilters: JSON.parse(queryParams || '[]')
            };
            const metadata = await postFetch(`${process.env.REACT_APP_SERVICES_BASE_URL_CRATECRAB}/Metadata/load-metadata`, JSON.stringify(query), auth.user.access_token);
            console.log(metadata);
            setRowCount(metadata.metadataViewItems.length);
            setQueryParamMetadataRows(metadata.metadataViewItems.map(item => {
                let model = item.value.model
                model.customTags = JSON.stringify(model.customTags);
                return model;
            }));
            setMetadataByQueryParamsLoading(false);// TODO: return total number of rows.
        } catch(e) {
            // TODO: handle this.
        }
        setIsRetrieving(false);
    }

    return (
        <>
            <CustomAccordion
                accordionText="Get Metadata By Query Fields"
                accordionId="loadmetadata"
                expanded={expanded}
                handleChange={handleChange}
                fontSize={16}
                accordionBody={
                    <>
                        <div className="s12">
                            <p style={{textAlign: "left"}}><em>[&#123; "name":"FileId|OrgId|FileName|UserId", "queryOperator":"contains|does not contain|starts with|ends with|greater than|less than|equal|greater or equal|less or equal", "value":"...", "valueType":"string" &#125;, ...]. Leave blank to return all results.</em></p>
                        </div>
                        <div className="input-field col s12">
                            <textarea
                                id="metadata-query-params"
                                className="materialize-textarea"
                                value={queryParams}
                                onChange={e => setQueryParams(e.target.value)}
                            ></textarea>
                            <label htmlFor="metadata-query-params">E.g. [&#123; "name": "FileId", "queryOperator": "equal", "value": "...", "valueType": "string" &#125;]</label>
                        </div>

                        <Button
                            node="button"
                            waves="light"
                            onClick={loadMetadata}
                        >
                            Load Metadata
                        </Button>


                        {isRetrieving &&
                            <div className="form" style={{marginTop: '10px', width: '50%'}}>
                                <div class="">Retrieving file data...</div>
                                <progress id="filedata" style={{width: "0", minWidth: "100%", }} />
                            </div>
                        }
                        {!isRetrieving &&
                            <div className="input-field col s12">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Org Id</th>
                                            <th>User Id</th>
                                            <th>File Name</th>
                                            <th>File Id</th>
                                            <th>Custom Tags</th>
                                        </tr>
                                        {Object.entries(queryParamMetadataRows || {}).map((obj, index) => (
                                            <tr className="table-row">
                                                <td key={`tablevalue-org-id-${index}`}>{obj[1]['orgId']}</td>
                                                <td key={`tablevalue-user-id-${index}`}>{obj[1]['userId']}</td>
                                                <td key={`tablevalue-file-name-${index}`}>{obj[1]['fileName']}</td>
                                                <td key={`tablevalue-file-id-${index}`}>{obj[1]['fileId']}</td>
                                                <td key={`tablevalue-custom-tags-${index}`}>{obj[1]['customTags']}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </>
                }
            ></CustomAccordion>
        </>
    );
}

export default MetadataServices;