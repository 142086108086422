import React from "react";
import {AuthProvider} from "react-oidc-context";
import AppContent from "./Pages/AppContent";
import {OIDC_CONFIG} from "./Config";

const App = () => (
    <AuthProvider {...OIDC_CONFIG}>
        <AppContent/>
    </AuthProvider>
);

export default App;