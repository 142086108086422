import ApiService from "./ApiService";
import type {UserInfoRequest} from "../Models/Requests/UserInfoRequest";


class AuthService {
    constructor(service: ApiService) {
        this.api = service;
    }

    async userInfo(request: UserInfoRequest) {
        this.api.service.defaults.headers['Authorization'] = `Bearer ${request.token}`;
        return this.api.service.get(`/connect/userinfo`);
    }

    async createDynamicLoginLink(token, scheme, returnUrl, origin) {
        this.api.service.defaults.headers['Authorization'] = `Bearer ${token}`;
        return this.api.service.get(`/Organization/External/Login/Authorization?scheme=${scheme}&returnUrl=${returnUrl}&origin=${origin}`);
    }

    async getActiveConnections(token) {
        this.api.service.defaults.headers['Authorization'] = `Bearer ${token.toString()}`;
        return this.api.service.get(`/Organization/External/Login/Connections`);
    }

    async deleteExternalLogin(token, id) {
        this.api.service.defaults.headers['Authorization'] = `Bearer ${token.toString()}`;
        return this.api.service.delete(`/organization/external/login/${id}`);
    }
}

export default AuthService;




