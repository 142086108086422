import React from 'react'
import 'materialize-css';

const ShowLogs = ({query}) => {

    return (
        <div>
            <a className="btn btn-small" href={`https://app.datadoghq.com/logs?live=true&query=*${query}*`}
               target="_blank" rel="noreferrer">Open Logs</a>
        </div>
    );

}

export default ShowLogs;