import React from 'react';
import 'materialize-css';
import {Button} from 'react-materialize';

const ResetButton = ({resetValues}) => {
    return (
        <Button
            key={'reset'}
            node="button"
            waves="light"
            className="btn btn-small materialize-red"
            onClick={resetValues}
        >
            Reset
        </Button>
    );
}


export default ResetButton;