import React, {useState} from "react";
import {Button, Collection, CollectionItem} from "react-materialize";
import CSPDropdown from "../Components/CSPDropdown";
import Input from "../Components/Input";
import Base62Str from "base62str";

const ListFolderContents = () => {
    const [cSPName, setCSPName] = useState(null);
    const [token, setToken] = useState(null);
    const [folderId, setFolderIds] = useState("root");
    const [folderContents, setFolderContents] = useState([]);

    const base62 = Base62Str.createInstance();

    async function getFolderIds() {
        const url = `${process.env.REACT_APP_SERVICES_BASE_URL_DELIVERY}/v1/accounts/me/storage/folders/${folderId}/contents`;
        console.log("the folderId is: " + folderId);
        fetch(url, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                serviceName: cSPName,
                Authorization: `Bearer ${token}`,
            },
        })
            .then(function (response) {
                console.log(response);
                return response.json();
            })
            .then(function (data) {
                console.log(data.objects);

                setFolderContents([...data.objects]);
                console.log(folderContents);
            }).catch((e) => {
            console.warn('Error request folder contents!', e)
        });
    }

    function decodeIds(id) {
        console.log(id);
        var decodedId = base62.decodeStr(id);
        console.log(decodedId);
        return decodedId;
    }

    return (
        <>
            <div className="form">
                <CSPDropdown setCSP={setCSPName}/>
                <Input label={"Customer GUID"} setInput={setToken}/>
                <Input label={"Folder Id"} setInput={setFolderIds}/>
                <Button
                    node="button"
                    style={{
                        marginRight: "5px",
                    }}
                    waves="light"
                    onClick={getFolderIds}
                >
                    List Folder Contents
                </Button>
            </div>

            <Collection header="Folder Contents" className="content-list">
                {folderContents.map((item, index) => (
                    <CollectionItem
                        className="collection-item"
                        key={index}
                    >
                        <div>
                            <p><span id="bold">Type: </span>{item.type}</p>
                            <p><span id="bold">Name:</span> {item.name}</p>
                            <p><span id="bold">Id: </span> {item.id}</p>
                            <p><span id="bold"> Decoded Id:</span> {decodeIds(item.id)}</p>
                        </div>
                    </CollectionItem>
                ))}
            </Collection>
        </>
    );
};

export default ListFolderContents;
