import {React, useEffect, useState} from 'react';
import _ from 'lodash';
import RestGetFiles from '../Components/RestGetFiles';
import {GrabPayloadModel} from '../Models/GrabPayloadModel';
import {useAuth} from "react-oidc-context";
import FilesDropdown from "../Components/FilesDropdown";

const GrabPage = () => {

    const [CSPName, setCSPName] = useState(null);
    const [Corgi, setCorgi] = useState(null);
    const [FileIds, setFileIds] = useState(null);
    const auth = useAuth();

    useEffect(() => {
        setCorgi(auth.user.profile['c:org:id']);
    }, [auth.user.access_token, auth.user.profile]);

    const getFileIds = () => {
        if(CSPName.toLowerCase() === "sharepoint"){
            return _.split(FileIds, ';')
        }
        else {
            return _.split(FileIds, ',')
        }
    }

    const BuildPayloadModel = () => {
        let audienceName = CSPName;
        if (CSPName.toString().toLowerCase().indexOf('s3') !== -1) {
            audienceName = "s3";
        }
        if (CSPName.toString().toLowerCase().indexOf('sftp') !== -1) {
            audienceName = "sftp";
        }
        const payloadModel = [new GrabPayloadModel(audienceName, getFileIds(), Corgi)];
        console.log("payload model: ", JSON.stringify(payloadModel))
        return JSON.stringify(payloadModel);
    }

    return (
        <div className="form">
            <h3>Grab Form</h3>
            <FilesDropdown
                setFileId={setFileIds}
                setCspName={setCSPName}
                user={auth.user}
            />
            <h3>Data for Grab Request</h3>
            <div className="row left-align">
                <div className="col s2">Conga Org Id</div>
                <div className="col s10">
                    {Corgi}
                </div>
            </div>

            <div className="row left-align">
                <div className="col s2">CSP Name</div>
                <div className="col s10">{CSPName}</div>
            </div>

            <div className="row left-align">
                <div className="col s2">File Ids</div>
                <div className="col s10">{FileIds}</div>
            </div>
            <RestGetFiles buildMessage={BuildPayloadModel} user={auth.user}/>
        </div>
    );
}

export default GrabPage;