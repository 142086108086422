import * as R from 'ramda';
import * as React from 'react';
import {JSONTree} from "react-json-tree";

export interface IJsonTreeViewer {
    data: any;
    title: string;
    shouldExpandNode?: (keyPath: Array<string | number>, data: [any] | {}, level: number) => boolean;
}

export default class JsonTreeViewer extends React.Component<IJsonTreeViewer, any> {
    renderJsonData() {
        return R.not(R.isEmpty(this.props.data)) && R.not(R.isNil(this.props.data)) ? (
            <>
                <h5>{this.props.title}</h5>
                <JSONTree data={this.props.data} theme="bright" shouldExpandNode={this.props.shouldExpandNode}/>
            </>
        ) : null;
    }

    render() {
        return this.renderJsonData();
    }
}
