import ApiService from "./ApiService";


class StatusService {
    constructor(service: ApiService) {
        this.api = service;
    }

    async getStatus(id) {
        return this.api.service.get(`/v1/Status/${id}`);
    }
}

export default StatusService;

