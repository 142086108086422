import React from "react";
import {Button, Container} from "react-materialize";
import {useAuth} from "react-oidc-context";
import DD3Connections from "./DD3Connections";


const DD3Api = ({showLoginButtons}) => {
    const auth = useAuth();

    if (auth.isLoading) {
        return (<div>loading</div>);
    }
    if (!auth.isAuthenticated && showLoginButtons) {
        return (
            <Container className="mt4">
                <Button onClick={function () {
                    auth.signinRedirect().then(res => {
                        console.log('user signed with redirect');
                    }).catch(reason => {
                        console.log('error signing in', reason);
                    }).finally(() => {
                        console.log('finally');
                    });
                }}>
                    Authorize
                </Button>
            </Container>
        )
    }
    if (!auth.isAuthenticated) {
        return (
            <Container className="mt4">
                <Button onClick={function () {
                    auth.signinRedirect().then(res => {
                        console.log('user signed with redirect');
                    }).catch(reason => {
                        console.log('error signing in', reason);
                    }).finally(() => {
                        console.log('finally');
                    });
                }}>
                    Authorize
                </Button>
            </Container>
        )
    }


    return (
        <div>
            <div className="row">
                <div className="col s12 center-align">
                    <DD3Connections/>
                </div>
            </div>
            <div className={!showLoginButtons ? 'hide' : ''}>
                <Button onClick={function () {
                    auth.removeUser().then(res => {
                        console.log('removed user', res);
                    }).catch(reason => {
                        console.log(reason);
                    }).finally(() => {
                        console.log('finally');
                    });
                }}>
                    Logout
                </Button>
            </div>
        </div>
    );
}

export default DD3Api;