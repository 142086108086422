import React, {useState} from "react";
import {useAuth} from "react-oidc-context";
import ApiService from "../Services/ApiService";
import StatusService from "../Services/StatusService";
import {useInterval} from "../Services/UseInterval";
import JsonTreeViewer from "../Auth/JsonTreeViewer";
import ShowLogs from "./ShowLogs";

const PollStatus = ({correlationId, showPresignedUrl, index}) => {

    const auth = useAuth();
    const [StatusJson, setStatusJson] = useState(null);
    const [StatusMessages, setStatusMessages] = useState(null);
    const [IsPolling, setIsPolling] = useState(true);
    const [PresignedPandaUrl, setPresignedPandaUrl] = useState(true);
    const [CurrentPollCount, setCurrentPollCount] = useState(0);
    const maxPollCount = 90;
    const pollingInterval = 2;

    async function updateStatus() {
        setPresignedPandaUrl(`${process.env.REACT_APP_SERVICES_BASE_URL_PRESIGNEDPANDA_FILES}/v1/presignedservice/${correlationId}`);
        let statusService = new StatusService(new ApiService(process.env.REACT_APP_SERVICES_BASE_URL_STATUS, auth.user.access_token));
        let response = await statusService.getStatus(correlationId);
        console.log('status response: ', response.status);
        const statuses = response.data['statusPersistanceRecord']['envelope'].map(item => {
            return item['status'];
        });
        setStatusMessages(statuses);
        setStatusJson(response.data['statusPersistanceRecord']);
    }

    function checkForComplete() {
        if (StatusMessages !== null) {
            const messages = StatusMessages.map(item => {
                return item.message
            });
            if (messages.indexOf('Completed') !== -1 || messages.indexOf('Error') !== -1) {
                setIsPolling(false);
            }
        }
    }

    useInterval(async () => {
        if (correlationId === null || correlationId.toString().length === 0 || !IsPolling) {
            return;
        }
        if (IsPolling) {
            if (CurrentPollCount < maxPollCount) {
                try {
                    await updateStatus();
                } catch (e) {
                    console.error('error updating status', e)
                }
                checkForComplete();
                setCurrentPollCount(CurrentPollCount + 1);
            } else {
                setIsPolling(false);
            }
        }
    }, 1000 * pollingInterval);
    if ((StatusMessages ?? []).length === 0) {
        return (<></>);
    }
    return (
        <div key={`${correlationId}-${index}`}>
            <h5>Status</h5>
            <div className="row">
                <button className={IsPolling ? "btn btn-small materialize-red" : "btn btn-small"} onClick={() => {
                    setIsPolling(!IsPolling);
                }}
                >{IsPolling ? `Stop ${CurrentPollCount}/${maxPollCount}` : 'Start Polling'}
                </button>
            </div>
            <div id="row" key={`${correlationId}-${index + 1}`}>
                {showPresignedUrl ? <div className="col s12">
                    {PresignedPandaUrl === null ? <></> :
                        <div className="left-align">
                            <h5>Presigned Panda URL</h5>
                            <a href={PresignedPandaUrl} target="_blank" rel="noreferrer">{PresignedPandaUrl}</a>
                        </div>}
                </div> : <></>}
                <div className="col s12">
                    <div className="left-align">
                        <h5>Logs</h5>
                        <ShowLogs query={correlationId.toString().split('-')[0]}/>
                    </div>
                </div>

                <div className="col s6">
                    <div className="left-align">
                        <h5>Messages</h5>
                        <table className="table table-small">
                            <thead>
                            <tr>
                                <td>Date</td>
                                <td>Message</td>
                                <td>Detail</td>
                            </tr>
                            </thead>
                            <tbody>
                            {(StatusMessages ?? []).map(item => {
                                return (<tr key={item['message'] + item['detail']}>
                                    <td>{item['startDateTime']}</td>
                                    <td>{item['message']}</td>
                                    <td>{item['detail']}</td>
                                </tr>)
                            })}
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="col s6">
                    <div className="left-align">
                        <JsonTreeViewer key={correlationId} data={StatusJson || {}} title={'Status Response'}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PollStatus;
