import {React, useState} from "react";
import "materialize-css";
import {Button} from "react-materialize";
import PollStatus from "./PollStatus";

const RestGetFiles = ({buildMessage, user}) => {

    const [CorrelationId, setCorrelationId] = useState(null);

    async function getFiles(url, message) {

        console.log("Message is: ", message);
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + user.access_token,
            },
            body: message,
        })
            .then((response) => response.json())
            .then((data) => {
                setCorrelationId(data.correlationId);
                console.log("correlationId is: ", data.correlationId, 'get files data:', data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    return (
        <div key={CorrelationId}>
            <Button
                key={CorrelationId}
                node="button"
                id="send-grab-button"
                waves="light"
                onClick={() => getFiles(process.env.REACT_APP_SERVICES_BASE_URL_INGRESSIBIS + "/Grab/v1/file/contents", buildMessage())}
            >
                Grab
            </Button>
            {CorrelationId === null ? <></> : <Button
                key={'reset'}
                node="button"
                waves="light"
                className="materialize-red"
                onClick={() => {
                    setCorrelationId(null)
                }}
            >
                Reset
            </Button>}

            <PollStatus correlationId={CorrelationId} showPresignedUrl={true}/>
        </div>
    );
};

export default RestGetFiles;