import React, {useState} from 'react';


const CopyTextButton = ({copyValue, copyText}) => {

    const [CopyText, setCopyText] = useState(copyText ?? 'Copy');
    const [ClassName, setClassName] = useState("btn");

    return (
        <button className={ClassName} onClick={() => {
            navigator.clipboard.writeText(copyValue).then(r => {
                setCopyText('Copied!');
                setClassName("btn btn-small green");
                setTimeout(function () {
                    setCopyText(null);
                    setClassName("btn");
                }, 1000)
            });
        }}
        >{CopyText !== null ? CopyText : copyText}
        </button>
    );
}

export default CopyTextButton;

