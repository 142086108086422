import React from 'react';
import {useAuth} from "react-oidc-context";
import LoginToCongaButton from "../Components/LoginWithCongaButton";
import AppContentProtected from "./AppContentProtected";
import Loading from "../Components/Loading";


export default function AppContent() {
    const auth = useAuth();
    if (auth.isLoading) {
        return (<Loading loadingText={'Authenticating...'}/>);
    }
    if (auth.isAuthenticated && auth.user.scope.indexOf('openid') !== -1 && Object.keys(auth.user.profile).indexOf('c:org:id') !== -1) {
        return (<AppContentProtected/>);
    }

    return (
        <div className="App" id="app-content-login">
            <div className="container">
                <div className="row">
                    <div className="col s4"/>
                    <div className="col s4 center-align">
                        {auth.isAuthenticated ? <div className="row">
                            <p className="materialize-red white-text">
                                Could not find conga org id.
                            </p>
                            <p>
                                Please delete all cookies, logout of all Salesforce accounts, and authorize again.
                            </p>
                            <p>
                                <a href="https://login.salesforce.com/" target="_blank"
                                   rel="noreferrer">https://login.salesforce.com/</a>
                            </p>
                        </div> : ""}
                        <LoginToCongaButton/>
                    </div>
                    <div className="col s4"/>
                </div>
            </div>
        </div>
    );
}
