// src/Posts.jsx
import React, {useState} from "react"
import {useAuth} from "react-oidc-context"
import {Icon, Navbar, NavItem} from "react-materialize";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ListFolderContents from "./ListFolderContents";
import CrateServices from "./Crate/CrateServices";
import PandaServices from "./PresignedPanda/PandaServices";
import GrabPage from "./GrabPage";
import SendPage from "./SendPage";
import AuthService from "../Services/AuthService";
import ApiService from "../Services/ApiService";
import Loading from "../Components/Loading";
import DD3Api from "./dd3/DD3Api";
import DD2Login from "./dd2/DD2Login";
import CopyTextButton from "../Components/CopyTextButton";

const version = require('../../package.json')['version']

const AppContentProtected = () => {
    const auth = useAuth()
    const [userInfo, setUserInfo] = useState(null)
    const [environment, setEnvironment] = useState(process.env['REACT_APP_ENVIRONMENT'])

    React.useEffect(() => {
        setEnvironment(process.env.REACT_APP_ENVIRONMENT);
        (async () => {
            try {
                if (Object.keys(auth.user.profile).indexOf('c:org:id') !== -1) {
                    setUserInfo(auth.user.profile)
                } else {
                    let api = new AuthService(new ApiService(process.env.REACT_APP_SERVICES_BASE_URL_AUTH, auth.user.access_token));
                    const response = await api.userInfo({token: auth.user.access_token});
                    const userInfo = await response.data;
                    setUserInfo(userInfo)
                    console.log('loaded user info', userInfo);
                }
            } catch (e) {
                console.error(e)
            }
        })()
    }, [auth, auth.user, auth.user.access_token])

    if (!userInfo) {
        return (<Loading loadingText={'Authorizing...'}/>);
    }

    async function logout() {
        await auth.clearStaleState();
        await auth.removeUser();
    }

    return (
        <div className="App">
            <Navbar
                alignLinks="right"
                id="brand-container"
                brand={<div className="brand-container">DocDelivery <b>version:</b> {version} <b>org:</b> {userInfo['c:org:name']} <b>env:</b> {environment}</div>}
                menuIcon={<Icon>menu</Icon>}
                options={{
                    draggable: false,
                    preventScrolling: true,
                }}
            >
                <NavItem>
                    <CopyTextButton copyText={'Token'} copyValue={auth.user.access_token}/>
                </NavItem>
                <NavItem>
                    <CopyTextButton copyText={'Org Id'} copyValue={userInfo['c:org:id']}/>
                </NavItem>
                <NavItem href={`/`}>
                    Home
                </NavItem>
                <NavItem href={`/dd3/login`}>
                    DD3
                </NavItem>
                <NavItem href={`/dd2/login`}>
                    DD2
                </NavItem>
                <NavItem href={`/grab`}>
                    Grab
                </NavItem>
                <NavItem href={`/send`}>
                    Send
                </NavItem>
                <NavItem href={`/list`}>
                    List
                </NavItem>
                <NavItem href={`/crateservices`}>
                    Crate
                </NavItem>
                <NavItem href={`/pandaservices`}>
                    PreSigned
                </NavItem>
                <NavItem onClick={logout} className="materialize-red">
                    Logout
                </NavItem>
            </Navbar>
            <div id="body">
                <BrowserRouter>
                    <Routes>
                        <Route strict="false" path={`/`} element={<DD3Api/>}/>
                        <Route strict="false" path={`/dd3/login`} element={<DD3Api/>}/>
                        <Route strict="false" path={`/dd2/login`} element={<DD2Login/>}/>
                        <Route strict="false" path={`/list`} element={<ListFolderContents/>}/>
                        <Route strict="false" path={`/grab`} element={<GrabPage/>}/>
                        <Route strict="false" path={`/send`} element={<SendPage user={auth.user}/>}/>
                        <Route strict="false" path={`/crateservices`} element={<CrateServices user={auth.user}/>}/>
                        <Route strict="false" path={`/pandaservices`} element={<PandaServices user={auth.user}/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
        </div>
    )
}

export default AppContentProtected