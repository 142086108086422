import ApiService from "./ApiService";
import {AxiosResponse} from "axios";
import Base62Str from "base62str";


class AnimalService {
    constructor(service: ApiService) {
        this.api = service;
    }

    async listFolderContents(token, folderId) {
        this.api.service.defaults.headers['Authorization'] = `Bearer ${token}`;
        return this.api.service.get(`/v1/folders/${folderId}/contents`);
    }

    async listFolderContentsRecursive(folderId, depth = 1): Promise<{ folders: *[], data: *[], files: *[] }> {
        let main = await this.api.service.get(`/v1/folders/${folderId}/contents`);
        let promises: AxiosResponse<any>[] = [];
        let result: { folders: any[], data: any[], files: any[] } = {
            'data': main.data['objects'],
            'folders': main.data['objects'].filter(item => item['type'] === 'folder'),
            'files': main.data['objects'].filter(item => item['type'] === 'file'),
        };
        let idMap = {}
        const base62 = Base62Str.createInstance();
        main.data['objects'].filter(item => item['type'] === 'folder').forEach(item => {
            const fid = item['id'];
            const decodedId = JSON.parse(base62.decodeStr(item['id']))
            item['requested'] = true
            idMap[fid] = {
                path: `/v1/folders/${fid}/contents`,
                folder: item,
                decodedId: decodedId
            }
            let promise: Promise<AxiosResponse<any>> = this.api.service.get(`/v1/folders/${fid}/contents`);
            promises.push(promise);
        })
        const aa = await this.mapResult(idMap, promises, result);
        idMap = aa.idMap;
        result = aa.result;

        result.folders.forEach(item => {
            const fid = item['id'];
            const decodedId = JSON.parse(base62.decodeStr(item['id']))
            if (!item['requested']) {
                idMap[fid] = {
                    path: `/v1/folders/${fid}/contents`,
                    folder: item,
                    decodedId: decodedId
                }
                item['requested'] = true;
                let promise: Promise<AxiosResponse<any>> = this.api.service.get(`/v1/folders/${fid}/contents`);
                promises.push(promise);
            }

        });
        const bb = await this.mapResult(idMap, promises, result);
        idMap = bb.idMap;
        result = bb.result;
        return Promise.resolve(result);
    }

    async mapResult(idMap, promises, result) {
        const base62 = Base62Str.createInstance();
        for (const response: AxiosResponse<any> of await Promise.all(promises)) {
            response.data['objects'].forEach(item => {
                item['requested'] = false
                item['decodedId'] = JSON.parse(base62.decodeStr(item['id']));
                try {
                    item['decodedParent'] = JSON.parse(base62.decodeStr(item['parent']['id']));
                } catch (e) {
                    console.warn(e);
                }
                result.data.push(item);
                if (item['type'] === 'file') {
                    result.files.push(item);
                }
                if (item['type'] === 'folder') {
                    result.folders.push(item);
                }
            })
        }
        return {idMap: idMap, result: result};
    }


    async search(token, term) {
        this.api.service.defaults.headers['Authorization'] = `Bearer ${token}`;
        return this.api.service.get(`/v1/search?q=${term}`);
    }

    async createLink(token, file_id, serviceName) {
        this.api.service.defaults.headers['Authorization'] = `Bearer ${token}`;
        this.api.service.defaults.headers['ServiceName'] = serviceName;
        return this.api.service.post(`${process.env.REACT_APP_SERVICES_BASE_URL_DELIVERY}/v1/accounts/me/storage/links`, {
            'file_id': file_id
        });
    }

    async getActiveConnections() {
        return this.api.service.get(`/v1/Account/Connections`);
    }

    async healthcheck() {
        return this.api.service.get(`/healthcheck`);
    }


    async saveConnection(access_key, secret_key) {
        return this.api.service.post(`/v1/Account/Register`, {
            'access_key': access_key,
            'secret_key': secret_key
        });
    }

    async saveSftpConnection(username, password,host,port) {
        return this.api.service.post(`/v1/Account/Register`, {
            'username': username,
            'password': password,
            'host': host,
            'port': port
        });
    }



}

export default AnimalService;