import React from 'react';
import {Button, Select} from 'react-materialize';
import {ExternalProvider} from "../Config/ExternalProvider";
import {ExternalProviders} from "../Config/ExternalProviders";


const CSPDropdown = ({setCSP, reset}) => {

    const onChange = (event) => {
        const cspName = event.target.value;
        console.log(`updated CSP: `, cspName);
        if (cspName !== null) {
            let externalProvider: ExternalProvider = ExternalProviders.filter(item => item.filePickerProvider.toLowerCase() === cspName.toString().toLowerCase() || item.scheme === cspName.toString().toLowerCase())[0];
            console.log(`updated CSP: `, externalProvider);
        }
        setCSP(event.target.value)
    }
    const resetCSP = () => {
        setCSP(null);
        reset(null);
    }

    return (
        <div className="row">
            <div className="col s6">
                <Select defaultValue={""} onChange={onChange}>
                    <option
                        disabled
                        value=""
                    >
                        Please select a CSP
                    </option>
                    {ExternalProviders.map(item => <option key={item.filePickerProvider}
                                                           value={item.filePickerProvider}>{item.displayName}</option>)}
                </Select>
            </div>
            <div className="col s6 left-align">
                <Button
                    key={'reset'}
                    node="button"
                    waves="light"
                    className="btn btn-small materialize-red"
                    onClick={resetCSP}
                >
                    Reset
                </Button>
            </div>
        </div>
    );
}

export default CSPDropdown;