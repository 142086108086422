import React from 'react';
import 'materialize-css';
import {TextInput} from 'react-materialize';

const Input = ({label, setInput, defaultValue=null}) => {

    const onChange = (event) => {
        setInput(event.target.value)
    }

    return (
        <TextInput label={label} onChange={onChange} defaultValue={defaultValue}/>
    );

}


export default Input;