import {React, useState} from 'react';
import InitiateMultiFileUpload from './InitiateMultiFileUpload';
import { TextInput } from 'react-materialize';

const AddFile = () => {
    const [ fileList, setFileList ] = useState([]);

    const onFileInputChange = (fileInputEvent) => {
        const fileArr = Array.from(fileInputEvent.target.files);
        setFileList(fileArr);
    }

    const getInitiateMultiFileUrl = () => {
        console.log('in get initiate multi file url');
        return `${process.env.REACT_APP_SERVICES_BASE_URL_INGRESSIBIS}/multipart/multifile/initiate?fileCount=${fileList.length}`;
    }

    return (
        <>
            <div className="form">
                <p>Upload Selected Files to Conga Crate</p>
                <TextInput label="File(s)" type="file" multiple onChange={onFileInputChange} />
            </div>
            <InitiateMultiFileUpload fileList={fileList} calloutUrl={getInitiateMultiFileUrl} index={0} />
        </>
    );
}

export default AddFile;