import {useAuth} from "react-oidc-context";
import React from "react";

export default function LoginToCongaButton() {
    const auth = useAuth();
    return (
        <div className="container">
            <div className="row">
                <div className="col s12">
                    <button className="btn-large login-button" onClick={async () => {
                        await auth.signinRedirect({
                            extraQueryParams: {
                                'acr_values': 'idp:Salesforce'
                            }
                        }).then(res => {
                            console.log('sign in res', res);
                        })
                    }}>
                        Authorize with Salesforce
                    </button>
                </div>
                <div className="col s12 white">
                    <hr/>
                </div>
                <div className="col s12">
                    <button className="btn-large login-button" onClick={async () => {
                        await auth.signinRedirect({
                            extraQueryParams: {
                                'acr_values': 'idp:SalesforceSandbox'
                            }
                        }).then(res => {
                            console.log('sign in res', res);
                        })
                    }}>
                        Authorize with Salesforce Sandbox
                    </button>
                </div>
            </div>
        </div>
    )
}
