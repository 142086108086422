import { React, useState } from "react";
import "materialize-css";
import { Button } from "react-materialize";
import { putFetch, postFetch } from '../../Services/HttpUtil';
import { InternalErrorException } from "@aws-sdk/client-sns";
import PollStatus from '../../Components/PollStatus';
import {useAuth} from "react-oidc-context";

const CHUNK_SIZE = 5242880 //5mb

const InitiateMultiFileUpload = ({ fileList, calloutUrl, index }) => {
    const auth = useAuth();
    const [currentCorrelationId, setCurrentCorrelationId] = useState(null);

    const [ isInitiate, setIsInitiate ] = useState(false);
    const [ initiateStatus, setInitiateStatus ] = useState('');

    const [ isUploadParts, setIsUploadParts ] = useState(false);
    const [ uploadPartsStatus, setUploadPartsStatus ] = useState('');

    const [ isCompleteUpload, setIsCompleteUpload ] = useState(false);
    const [ uploadCompleteStatus, setUploadCompleteStatus ] = useState('');

    const initiateMultiFile = async () => {
        setIsInitiate(true);
        console.log("Url: ", calloutUrl());

        let response;
        try{
            response = await postFetch(calloutUrl(), "", auth.user.access_token);
            console.log("Response Data: ", response);
            setInitiateStatus('success');
            setIsInitiate(false);
            uploadFiles(response);
        } catch(err){
            console.log('Initiate multi file upload error:', err);
            setIsInitiate(false);
            setInitiateStatus('error');
        }
    }

    const uploadFiles = async (initiateData) => {
        setIsUploadParts(true);

        const currentCorrelationId = initiateData.correlationId;
        const currentTransactionId = initiateData.transactionId;
        const currentUploadInfoList = initiateData.uploadInfoList;

        let uploadInfoForComplete = {
            "CorrelationId": currentCorrelationId,
            "TransactionId": currentTransactionId,
            "UploadInfoList": []
        };

        let uploadError = false;

        for(let i = 0; i < fileList.length; i++){
            const file = fileList[i];
            const fileChunks = Math.ceil(file.size / CHUNK_SIZE);

            let startIndex = 0;
            let endIndex = CHUNK_SIZE + 1;

            const uploadInfo = currentUploadInfoList[i];

            let uploadParts = [];

            for(let i = 0; i < fileChunks; i++){
                const fileChunk = file.slice(startIndex, endIndex);

                console.log('File Chunk to upload', fileChunk);

                let partInfo;
                try{
                    const response = await putFetch(`${process.env.REACT_APP_SERVICES_BASE_URL_INGRESSIBIS}/multipart/multifile/${currentCorrelationId}/${currentTransactionId}/${uploadInfo.key}/${uploadInfo.uploadId}/1/${uploadInfo.batchId}`, fileChunk, auth.user.access_token);
                    console.log('Upload Part Response', response);
                    if(!response.dataObjectUploadMultiPartResponse.isSuccessful){
                        throw new InternalErrorException(`Status Code: ${response.dataObjectUploadMultiPartResponse.statusCode}`);
                    }

                    partInfo = response.dataObjectUploadMultiPartResponse.part;
                } catch(err){
                    console.log(`Upload Part for ${file.name} Error:`, err);
                    uploadError = true;
                    break;
                }

                const tag = partInfo.tag.replace(/["]+/g, '');

                uploadParts.push(
                    {
                        "PartNumber": partInfo.partNumber,
                        "Tag": tag
                    }
                );
                startIndex = endIndex;
                endIndex = startIndex + CHUNK_SIZE + 1;
            }

            console.log('Upload Parts', uploadParts);
            uploadInfoForComplete.UploadInfoList.push({
                "Filename": file.name,
                "Key": uploadInfo.key,
                "UploadId": uploadInfo.uploadId,
                "PreSignedUrl":  '',
                "Label": file.name === 'metadata.json' ? 'metadata' : '',
                "Parts": uploadParts
            });
        }

        console.log(uploadInfoForComplete);
        setIsUploadParts(false);

        if(!uploadError){
            setUploadPartsStatus('success');
            completeMultiFileUpload(JSON.stringify(uploadInfoForComplete));
        } else{
            setUploadPartsStatus('error');
        }
    }

    const completeMultiFileUpload = async (uploadInfoForComplete) => {
        setIsCompleteUpload(true);

        let response;
        try{
            response = await postFetch(`${process.env.REACT_APP_SERVICES_BASE_URL_INGRESSIBIS}/multipart/multifile/complete`, uploadInfoForComplete, auth.user.access_token);
            console.log(response);
            setUploadCompleteStatus('success');
            setCurrentCorrelationId(response.correlationId);
        } catch(err){
            console.log('Complete multi file upload error:', err);
            setUploadCompleteStatus('error');
        }

        setIsCompleteUpload(false);
    }

    const progressBar = () => {
        return (
            <div className="progress" style={{verticalAlign: 'middle', display: 'inline-block', width: '12rem', height: '5px', margin: '0 10px'}}>
                <div className="indeterminate"></div>
            </div>
        );
    }

    const checkMark = () => {
        return (
            <i className="small material-icons" style={{color: '#2bbbad', margin: '0 10px', verticalAlign: 'middle'}}>check</i>
        );
    }

    const errorMark = () => {
        return (
            <i className="small material-icons" style={{color: 'red', margin: '0 10px', verticalAlign: 'middle'}}>close</i>
        );
    }
    return (
        <>
            <Button
                node="button"
                waves="light"
                style={{width: 'auto'}}
                onClick={() => initiateMultiFile()}
            >
                Initiate Multi File Upload
            </Button>

            <div style={{marginTop: '10px'}}>
                <div id="status-box" style={{outline: 'none', marginBottom: '5px', textAlign: 'left'}}>
                    Initiating Multi File Upload
                    {isInitiate && progressBar()}
                    {!isInitiate && initiateStatus === 'success' && checkMark()}
                    {!isInitiate && initiateStatus === 'error' && errorMark()}
                </div>

                <div id="status-box" style={{outline: 'none', marginBottom: '5px', textAlign: 'left'}}>
                    Uploading Inidividual Parts
                    {isUploadParts && progressBar()}
                    {!isUploadParts && uploadPartsStatus === 'success' && checkMark()}
                    {!isUploadParts && uploadPartsStatus === 'error' && errorMark()}
                </div>

                <div id="status-box" style={{outline: 'none', marginBottom: '5px', textAlign: 'left'}}>
                    Completing Multi File Upload
                    {isCompleteUpload && progressBar()}
                    {!isCompleteUpload && uploadCompleteStatus === 'success' && checkMark()}
                    {!isCompleteUpload && uploadCompleteStatus === 'error' && errorMark()}
                </div>
            </div>
            {currentCorrelationId &&
                <>
                    <div id="status-box" style={{outline: 'none', textAlign: 'left'}}>Correlation Id: {currentCorrelationId}</div>
                    <PollStatus correlationId={currentCorrelationId} showPresignedUrl={true} congaToken={auth.user.access_token} index={index} />
                </>
            }
        </>
    );
};

export default InitiateMultiFileUpload;
