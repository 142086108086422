import {ExternalProvider} from "./ExternalProvider";

export const ExternalProviders: ExternalProvider[] = [
    {
        scheme: "Google",
        provider: "Google",
        displayName: "Google Drive",
        filePickerProvider: "gdrive",
        scopes: [
            'openid',
            'https://www.googleapis.com/auth/drive',
            'https://www.googleapis.com/auth/docs',
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email'
        ],
        url: process.env.REACT_APP_SERVICES_BASE_URL_GOOGLE,
        audience: "googlegoose"
    },
    {
        scheme: "Microsoft",
        provider: "Microsoft",
        displayName: "SharePoint",
        filePickerProvider: "sharepoint",
        scopes: [
            'https://graph.microsoft.com/User.Read',
            'https://graph.microsoft.com/Sites.Read.All',
            'https://graph.microsoft.com/Files.ReadWrite.All',
            'offline_access'
        ],
        url: process.env.REACT_APP_SERVICES_BASE_URL_SHAREPOINT,
        audience: "sharepointsteward"
    },
    {
        scheme: "Microsoft",
        provider: "Microsoft",
        displayName: "OneDrive",
        filePickerProvider: "onedrive",
        scopes: [
            'https://graph.microsoft.com/User.Read',
            'https://graph.microsoft.com/Sites.Read.All',
            'https://graph.microsoft.com/Files.ReadWrite.All',
            'offline_access'
        ],
        url: process.env.REACT_APP_SERVICES_BASE_URL_ONEDRIVE,
        audience: "onedriveox"
    },
    {
        scheme: "Dropbox",
        provider: "Dropbox",
        displayName: "Dropbox",
        filePickerProvider: "dropbox",
        scopes: [
            'account_info.read',
            'files.metadata.read',
            'files.content.write',
            'files.content.read'
        ],
        url: process.env.REACT_APP_SERVICES_BASE_URL_DROPBOX,
        audience: "dropboxdingo"
    },
    {
        scheme: "Box",
        provider: "Box",
        displayName: "Box",
        filePickerProvider: "box",
        scopes: [
            'root_readwrite'
        ],
        url: process.env.REACT_APP_SERVICES_BASE_URL_BOX,
        audience: "boxbasilisk"
    },
    {
        scheme: "Salesforce",
        provider: "Salesforce",
        displayName: "Salesforce",
        filePickerProvider: "salesforce",
        scopes: [
            'full',
            'offline_access'
        ],
        url: process.env.REACT_APP_SERVICES_BASE_URL_SALESFORCE,
        audience: "salesforcesloth"
    },
    {
        scheme: "S3",
        provider: "S3",
        displayName: "S3",
        filePickerProvider: "s3squid",
        scopes: [],
        url: process.env.REACT_APP_SERVICES_BASE_URL_S3SQUID,
        audience: "s3squid"
    },
    {
        scheme: "SFTP",
        provider: "SFTP",
        displayName: "SFTP",
        filePickerProvider: "sftpspider",
        scopes: [],
        url: process.env.REACT_APP_SERVICES_BASE_URL_SFTPSPIDER,
        audience: "sftpspider"
    }
]
