import {React, useState} from 'react';
import { Button } from 'react-materialize';
import { getFetch, putFetch } from '../../Services/HttpUtil';
import CustomAccordion from '../../Components/CustomAccordion';
import {useAuth} from "react-oidc-context";

const SettingSettings = () => {
    const auth = useAuth();
    const [ settingQueryParams, setSettingQueryParams ] = useState({EnableCrateOptIn:false});
    const [ isChecked, setIsChecked] = useState(false);

    const [ isSetting, setIsSetting ] = useState(false);
    const [ settingSuccessful, setSettingSuccessful ] = useState(false);
    const [ settingSuccessfulMessage, setSettingSuccessfulMessage ] = useState('');
    const [ settingError, setSettingError ] = useState(false);
    const [ settingErrorMessage, setSettingErrorMessage ] = useState('');

    const [ isGettingSetting, setIsGettingSetting ] = useState(false);
    const [ settings, setSettings ] = useState({});
    const [ gettingSettingSuccessful, setGettingSettingSuccessful ] = useState(false);
    const [ gettingSettingError, setGettingSettingError ] = useState(false);
    const [ gettingSettingErrorMessage, setGettingSettingErrorMessage ] = useState('');

    const [ expanded, setExpanded ] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const settingSettings = async () => {
        setIsSetting(true);
        setSettingSuccessful(false);
        setSettingError(false);
        try {
            const settingSettingsResponse = await putFetch(`${process.env.REACT_APP_SERVICES_BASE_URL_CRATECRAB}/CrateSettings/crate-settings`, JSON.stringify(settingQueryParams || "{}"), auth.user.access_token);
            console.log(settingSettingsResponse);
            setSettingError(!settingSettingsResponse.isSuccessful);
            setSettingSuccessful(settingSettingsResponse.isSuccessful);
            settingSettingsResponse.isSuccessful ? setSettingSuccessfulMessage("Set settings ok") : setSettingErrorMessage(settingSettingsResponse.message);
        } catch(e) {
            console.log(e)
            setSettingSuccessful(false);
            setSettingError(true);
            setSettingErrorMessage("Unable to set settings");
        }
        setIsSetting(false);
    }

    const gettingSettings = async () => {
        setIsGettingSetting(true);
        setGettingSettingSuccessful(false);
        setGettingSettingError(false);
        try {
            const gettingSettingsResponse = await getFetch(`${process.env.REACT_APP_SERVICES_BASE_URL_CRATECRAB}/CrateSettings/crate-settings`, auth.user.access_token);
            console.log(gettingSettingsResponse);
            setGettingSettingError(!gettingSettingsResponse);
            setGettingSettingSuccessful(!!gettingSettingsResponse);
            setSettings(gettingSettingsResponse);
        } catch(e) {
            setGettingSettingSuccessful(false);
            setGettingSettingError(true);
            setGettingSettingErrorMessage("Unable to get settings");
        }
        setIsGettingSetting(false);
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setSettingQueryParams({EnableCrateOptIn:!isChecked});
    };

    return (
        <>
            <CustomAccordion
                accordionText="Set Settings"
                accordionId="getsettings"
                expanded={expanded}
                handleChange={handleChange}
                fontSize={16}
                accordionBody={
                    <>
                        <Button
                            node="button"
                            waves="light"
                            onClick={settingSettings}
                            style={{width: 'auto'}}
                        >
                            Set Settings
                        </Button>
                        <div style={{marginTop: '10px', width: '100%'}}>
                            <label>
                                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange}  />
                                Enable Crate Crab Opt-In
                            </label>
                        </div>

                        {isSetting &&
                            <div className="form" style={{marginTop: '10px', width: '50%'}}>
                                <div class="">Settings being set...</div>
                                <progress id="file" style={{width: "0", minWidth: "100%", }} />
                            </div>
                        }

                        {settingSuccessful &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">{settingSuccessfulMessage}</div>
                            </div>
                        }

                        {settingError &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">{settingErrorMessage}</div>
                            </div>
                        }
                    </>
                }
            ></CustomAccordion>
            <CustomAccordion
                accordionText="Get Settings"
                accordionId="setsettings"
                expanded={expanded}
                handleChange={handleChange}
                fontSize={16}
                accordionBody={
                    <>
                        <div className="input-field col s12">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Key</th>
                                        <th>Value</th>
                                    </tr>
                                    {Object.entries(settings || {}).map(([ key, value ]) => (
                                        <tr className="table-row">
                                            <td key={`tablevalue-${key}`}>{key}</td>
                                            <td key={`tablevalue-${value}`}>{`${value}`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Button
                            node="button"
                            waves="light"
                            onClick={gettingSettings}
                            style={{width: 'auto'}}
                        >
                            Get Settings
                        </Button>

                        {isGettingSetting &&
                            <div className="form" style={{marginTop: '10px', width: '50%'}}>
                                <div class="">Retrieving settings</div>
                                <progress id="file" style={{width: "0", minWidth: "100%", }} />
                            </div>
                        }

                        {gettingSettingSuccessful &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">Settings retrieved successfully</div>
                            </div>
                        }

                        {gettingSettingError &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">{gettingSettingErrorMessage}</div>
                            </div>
                        }
                    </>
                }
            ></CustomAccordion>
        </>
    );
}

export default SettingSettings;