import {React, useState} from 'react';
import {useAuth} from "react-oidc-context";
import CustomAccordion from '../../Components/CustomAccordion';
import MetadataServices from './MetadataServices';
import SettingsServices from './SettingsServices';
import FileServices from './FileServices';

const CrateServices = () => {
    const auth = useAuth();
    console.log(auth);

    const [ expanded, setExpanded ] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const accordionSections = [
        {
            title: 'Metadata Services',
            id: 'metadata',
            body: <MetadataServices />
        },
        {
            title: 'File Services',
            id: 'file',
            body: <FileServices />
        },
        {
            title: 'Settings Services',
            id: 'settings',
            body: <SettingsServices />
        },
    ];

    return (
        <>
            <div className="form">
                <p>Crate Crab Services</p>

                {accordionSections.map(section => {
                    return (
                        <CustomAccordion
                            key={section.id}
                            accordionText={section.title}
                            accordionId={section.id}
                            expanded={expanded}
                            handleChange={handleChange}
                            fontSize={24}
                            accordionBody={section.body}
                        />
                    );
                })}
            </div>
        </>
    );
}

export default CrateServices;