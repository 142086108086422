import React, {useEffect, useState} from "react";
import {Button, Card, Container, Row} from "react-materialize";
import {ExternalProviders} from "../../Config/ExternalProviders";

function anonymousLoginLink(provider): string {
    return `${process.env.REACT_APP_SERVICES_BASE_URL_AUTH}/external/anonymous/challenge?scheme=${provider.scheme}&scope=${provider.scopes.join(' ')}&returnUrl=${process.env.REACT_APP_HOST + "/dd2/login?state=" + provider.filePickerProvider}`;
}

export function GetQueryParams(search): any {
    return (function (a) {
        if (a === "") return {};
        const b = {};
        for (let i = 0; i < a.length; ++i) {
            const p = a[i].split('=', 2);
            if (p.length === 1)
                b[p[0]] = "";
            else
                b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
    })(search.substr(1).split('&'))
}

export function getAnimalInfo(): Promise<{ link: string, provider: string, label: string, externalLoginGrant: string }[]> {
    const results = [];
    for (const provider of ExternalProviders) {
        let key = null;
        if (window.localStorage.key(provider.filePickerProvider) !== null) {
            key = window.localStorage.getItem(provider.filePickerProvider);
        }
        results.push({
            link: anonymousLoginLink(provider),
            provider: provider.filePickerProvider,
            label: provider.displayName,
            externalLoginGrant: key,
        });
    }
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(results)
        }, 0)
    })
}


const DD2Login = () => {
    const [animalInfo, setAnimalInfo] = useState([]);


    function goTo(link) {
        window.location = link;
    }

    useEffect(() => {
        getAnimalInfo()
            .then(data => {
                    setAnimalInfo(data.map((item, index) => {
                        if (item.externalLoginGrant === null) {
                            return (<Row key={item.label}>
                                <Button key={item.provider} className={"buttons btn-large"} onClick={() => {
                                    goTo(item.link);
                                }}>
                                    <div>{item.label}</div>
                                </Button>
                            </Row>)
                        }
                        return (<Card key={item.label}>
                            <h5>{item.label} / {item.provider}</h5>
                            <div>{item.externalLoginGrant}</div>
                            <br/>
                            <Button key={item.provider} className="buttons materialize-red btn-large" onClick={() => {
                                item.externalLoginGrant = null;
                                console.log('logout', item, animalInfo);
                                window.localStorage.removeItem(item.provider);
                                window.location.reload();
                            }}>
                                Logout
                            </Button>
                        </Card>);
                    }));
                }
            );
    }, [animalInfo])


    const queryParams = GetQueryParams(window.location.search);
    if (Object.keys(queryParams).indexOf('state') !== -1 && Object.keys(queryParams).indexOf('externalLoginGrant') !== -1) {
        window.localStorage.setItem(queryParams.state, queryParams['externalLoginGrant']);
        window.location = window.location.origin + window.location.pathname;
    }
    return (
        <div>
            <h3>Get External Login Grant</h3>
            <p>Login with one of the providers below to receive your external login grant.</p>
            <hr/>
            <Container>
                {animalInfo ?? 'loading'}
            </Container>
        </div>
    );
};

export default DD2Login;
