import React, {useState} from "react";
import {Select, TextInput} from "react-materialize";
import AWS from "aws-sdk/dist/aws-sdk-react-native";
import {Line} from 'rc-progress';

const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    apiVersion: 'latest',
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION
    }
})

const GetPresignedUrl = ({setPresignUrl, prefix}) => {

    const [progress, setProgress] = useState(0);
    const [, setSelectedFile] = useState(null);
    const [FormPresignedUrl, setFormPresignedUrl] = useState('');
    const [PresignedUrlDuration, setPresignedUrlDuration] = useState(3600 * 6);

    const handleFileInput = (e) => {
        console.log('updating file input', e.target.files[0])
        setSelectedFile(e.target.files[0]);
        uploadFile(e.target.files[0]).then(r => console.log('upload done', r));
    }


    let url = "";
    const uploadFile = async (file) => {
        AWS.config.apiVersions['s3'] = '2006-03-01';

        let key = `dd3-ui/uploads/${file.name}`;
        if (prefix !== null && prefix.toString().length > 0) {
            key = `dd3-ui/uploads/${prefix}/${file.name}`;
        }
        const s3Config = {
            signatureVersion: "v4",
            params: {Bucket: S3_BUCKET},
            region: REGION,
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        };
        if (process.env.REACT_APP_AWS_ENDPOINT_URL !== null && process.env.REACT_APP_AWS_ENDPOINT_URL.toString().length > 0 && process.env.REACT_APP_AWS_ACCESS_KEY_ID === 'mock') {
            s3Config.endpoint = process.env.REACT_APP_AWS_ENDPOINT_URL;
            s3Config.useHttp = true;
            s3Config.s3ForcePathStyle = true;
        }
        let s3 = new AWS.S3(s3Config)
        AWS.config.update({
            region: process.env.REACT_APP_AWS_REGION,
            apiVersion: 'latest',
            credentials: {
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
            }
        })
        console.log('file', file);
        const params = {
            // ACL: 'public-read',
            Key: key,
            ContentType: file.type,
            Body: file,
        }
        const result = s3.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                if (err) console.log(err)
            });
        console.log('upload result', result);
        const presignParameters = {Bucket: S3_BUCKET, Key: key, Expires: PresignedUrlDuration};
        url = await s3.getSignedUrlPromise('getObject', presignParameters);
        console.log('Presigned URL', url);
        setFormPresignedUrl(url);
        setPresignUrl(url);
    }

    const onFormChange = (e) => {
        console.log('form change', e);
        if (e.target.value !== null) {
            setFormPresignedUrl(e.target.value);
            setPresignUrl(e.target.value);
        }
    }

    return (
        <div className="row">
            <div className="row">
                <div className="col s12">
                    <TextInput label={'Override Presigned URL'} placeholder={FormPresignedUrl} onChange={onFormChange}
                               defaultValue={FormPresignedUrl}/>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="col s4">
                        <h5>Presigned Url Duration</h5>
                    </div>
                    <div className="col s8">
                        <Select
                            id="select-presigned-url-duration"
                            multiple={false}
                            onChange={function noRefCheck(ev) {
                                console.log('changed presigned url duration', ev.target.value);
                                setPresignedUrlDuration(parseInt(ev.target.value));
                            }}
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: true,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value=""
                        >
                            <option value={3600}>
                                1 Hour
                            </option>
                            <option value={3600 * 6}>
                                6 Hours
                            </option>
                            <option value={3600 * 12}>
                                12 Hours
                            </option>
                        </Select>
                    </div>
                </div>
                <div className="col s4">
                    <h5>Upload a File</h5>
                    <input id="choose-file" className="file-upload file-field" type="file" onChange={handleFileInput}/>
                </div>
                <div className="col s8">
                    <h5>Upload Progress ({progress} %)</h5>
                    <Line percent={progress} strokeWidth={1} strokeColor="#1d7d74"/>
                </div>

            </div>
        </div>
    )

}

export default GetPresignedUrl;
