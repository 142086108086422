import React, {useEffect, useState} from 'react';
import AsyncSelect from "react-select/async";
import AnimalService from "../Services/AnimalService";
import ApiService from "../Services/ApiService";
import Base62Str from "base62str";
import {ExternalProviders} from "../Config/ExternalProviders";
import {ExternalProvider} from "../Config/ExternalProvider";
import Input from "./Input";
import ResetButton from "./ResetButton";
import {TextInput} from "react-materialize";


function decodeId(id) {
    return Base62Str.createInstance().decodeStr(id);
}

function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}


const ParentFolderIdDropdown = ({setParentFolderId, currentCsp, user}) => {
    const [FileList, setFileList] = useState(null);
    const [SelectedFileValue, setSelectedFileValue] = useState(null);
    const [SelectedParentFolderId, setSelectedParentFolderId] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [, setSelectedFileId] = useState(null);

    function resetValues() {
        setSelectedFileId(null);
        setSelectedFileValue(null);
        setSelectedParentFolderId(null);
        setParentFolderId(null);
    }

    async function handleDropdownSelection(val) {
        console.log('selected ', val);
        const decodedFolderId = JSON.parse(decodeId(val['id']));
        console.log('selected parent', decodedFolderId);
        setParentFolderId(decodedFolderId['id']);
        setSelectedParentFolderId(decodedFolderId['id']);
        setSelectedFileValue(val);
    }

    useEffect(() => {
        let externalProvider: ExternalProvider = ExternalProviders.filter(item => item.filePickerProvider.toLowerCase() === currentCsp.toString().toLowerCase() || item.scheme === currentCsp.toString().toLowerCase())[0];
        console.log('csp', currentCsp, externalProvider);
        setIsLoading(true);
        new AnimalService(new ApiService(externalProvider.url, user.access_token))
            .listFolderContents(user.access_token, 'root').then(res => {
            setFileList(res.data['objects'].filter(item => item['type'] === 'folder'));
            setIsLoading(false);
        });
    }, [currentCsp, user.access_token]);

    if (isLoading) {
        return (
            <div className="row">
                <div className="col s4">
                    <Input label={"Override Parent Folder Id"} setInput={setParentFolderId} defaultValue={SelectedParentFolderId}/>
                </div>
                <div className="col s8">Loading Folders...</div>
            </div>
        );
    }
    return (
        <div className="row">
            <div className="col s4">
                <TextInput label={"Override Parent Folder Id"} setInput={setParentFolderId} placeholder={SelectedParentFolderId ?? ""}/>
            </div>
            <div className="col s6">
                <AsyncSelect
                    cacheOptions
                    defaultOptions={FileList}
                    value={SelectedFileValue}
                    getOptionLabel={e =>
                        <div className="row">
                            <div className="col s8 left-align">
                                {`${e.type} | ${e.name}`}
                            </div>
                            <div className="col s4 right-align">
                                {bytesToSize(e.size)}
                            </div>
                        </div>
                    }
                    getOptionValue={e => e.name}
                    loadOptions={FileList}
                    onChange={handleDropdownSelection}
                />
            </div>
            <div className="col s2">
                <ResetButton resetValues={resetValues}/>
            </div>
        </div>
    );
}

export default ParentFolderIdDropdown;