import React, {useState} from 'react';
import AsyncSelect from "react-select/async";
import AnimalService from "../Services/AnimalService";
import ApiService from "../Services/ApiService";
import JsonTreeViewer from "../Auth/JsonTreeViewer";
import CSPDropdown from "./CSPDropdown";
import Base62Str from "base62str";
import Input from "./Input";
import {ExternalProviders} from "../Config/ExternalProviders";
import Loading from "./Loading";
import {TextInput} from "react-materialize";


function decodeId(id) {
    return Base62Str.createInstance().decodeStr(id);
}

function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}


const FilesDropdown = ({setFileId, setCspName, user}) => {
    const [FileValue, setFileValue] = useState('');
    const [FileList, setFileList] = useState(null);
    const [LoadingFileText, setLoadingFileText] = useState(null);
    const [SelectedFileValue, setSelectedFileValue] = useState(null);
    const [currentCSP, setCurrentCSP] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [SelectedFolderIdValue, setSelectedFolderIdValue] = useState(null);
    const [SearchTerm, setSearchTerm] = useState('pdf');
    const [, setSelectedFileId] = useState(null);


    async function getFileList(baseUrl, csp) {
        setSelectedFileId(null)
        setSelectedFileValue(null)
        setSelectedFolderIdValue(null);
        console.log(`get file list [${csp.displayName}] search term: ${SearchTerm}`);
        let objects = [];
        let res = {
            data: {
                objects: []
            }
        }
        if (csp.provider.toString().toLowerCase() === 's3') {
            const rootFolderContents = await new AnimalService(new ApiService(baseUrl, user.access_token))
                .listFolderContents(user.access_token, 'root');
            objects = objects.concat(rootFolderContents.data['objects']);
            res.data.objects = objects;
            return res;
        }
        const searchTermResponse = await new AnimalService(new ApiService(baseUrl, user.access_token))
            .search(user.access_token, SearchTerm);
        objects = objects.concat(searchTermResponse.data['objects']);

        let folder = 'root';
        if (csp.filePickerProvider.toString().toLowerCase() === 'salesforce') {
            const service = new AnimalService(new ApiService(baseUrl, user.access_token));
            const folderContentsResponse = await service.listFolderContents(user.access_token, folder);
            const libraryFolder = folderContentsResponse.data['objects'].filter((item) => item.name.toString().toLowerCase() === 'libraries')[0];
            let libraryFolderRes = await service.listFolderContents(user.access_token, libraryFolder['parent']['id']);
            objects = objects.concat(libraryFolderRes.data['objects']);
        }
        objects.sort((a, b) => {
            return parseInt(a['size']) >= parseInt(b['size'])
        });
        res.data.objects = objects;
        console.log(`returning file list [${csp.displayName}] search term: ${SearchTerm}`, res);
        return res;
    }

    const handleSelectFileChange = value => {
        setFileValue(value);
    };

    let loadFiles = async (baseUrl: string, csp) => {
        setIsLoading(true);
        setLoadingFileText(<div className="container">
            <Loading loadingText={`Loading files from ${baseUrl}`}/>
        </div>)
        getFileList(baseUrl, csp).then(res => {
            console.log('files: ', res.data)
            if (res.data['objects'] === undefined || res.data['objects'] === null) {
                setLoadingFileText(<div className="row">
                    <div className="col left-align">
                        <h5>Response Error</h5>
                        <p>
                            <b>message:</b> <span className="red-text">no files found!</span>
                        </p>
                        <p>
                            <b>url:</b>{baseUrl}
                        </p>
                    </div>
                </div>)
                return;
            }
            const fs = res.data['objects'].map((item, index) => {
                let res = item;
                res['decodedId'] = JSON.parse(decodeId(res['id']));
                return res;
            });
            fs.sort((a, b) => {
                return parseInt(a['size']) >= parseInt(b['size'])
            });
            setFileList(fs);
            setIsLoading(false);
        }).catch(err => {
            console.error('load files error: ', err);
            setLoadingFileText(<div className="row">
                <div className="col left-align">
                    <h5>Response Error</h5>
                    <p>
                        <b>message:</b> <span className="red-text">{err.message}</span>
                    </p>
                    <p>
                        <b>url:</b>{baseUrl}
                    </p>
                </div>
            </div>)
        })
    };

    function resetValues(val) {
        setCurrentCSP(null);
        setCspName(null);
        setSelectedFileId(null);
        setSelectedFolderIdValue(null);
        setSelectedFileValue(null);
        setFileValue(null);
        setFileId(null);
    }

    async function updateSelectedCSP(val) {
        console.log('update selected CSP', val);
        if (val === null) {
            resetValues();
            return;
        }
        let filter = ExternalProviders.filter(item => {
            if (item.provider.toString().toLowerCase() === val.toString().toLowerCase() || item.scheme.toLowerCase() === val.toString().toLowerCase() || item.filePickerProvider.toLowerCase() === val.toString().toLowerCase()) {
                return item;
            }
            return null;
        });
        const csp = filter[0];
        setCurrentCSP(csp);
        setCspName(csp.filePickerProvider);
        setSelectedFileId(null);
        setSelectedFolderIdValue(null);
        console.log('SelectedCSP: ', csp, 'file: ', FileValue);
        await loadFiles(csp.url, csp)
    }

    async function handleFilePick(val) {
        console.log('selected ', val);
        setFileId(val['decodedId']['id']);
        setCurrentCSP(currentCSP);
        setSelectedFileValue(JSON.parse(JSON.stringify(val)));
        setSelectedFolderIdValue(val['decodedId']);
        setSelectedFileId(val['decodedId']['id']);
    }

    if (currentCSP === null) {
        return (
            <div className="row">
                <div className="col s12">
                    <CSPDropdown setCSP={updateSelectedCSP} reset={resetValues}/>
                </div>
            </div>
        )
    }

    async function updateSearch(value) {
        console.log(`Override Search ${value}`, currentCSP);
        setSearchTerm(value);
        await loadFiles(currentCSP.url, currentCSP);
    }

    return (
        <div className="row">
            <div className="col s6">
                <CSPDropdown setCSP={updateSelectedCSP} reset={resetValues}/>
            </div>
            <div className="col s6">
                <TextInput label={'Override Search Term'} defaultValue={SearchTerm} onChange={async (e) => {
                    if (e.target.value.toString().length >= 3) {
                        await updateSearch(e.target.value);
                    }
                }}/>
                <Input label={"Override File Id"} setInput={setFileId}/>
            </div>
            {isLoading ?
                <div className="col s12">{LoadingFileText}</div> :
                <div className="col s12">
                    <AsyncSelect
                        cacheOptions
                        defaultOptions={FileList}
                        value={SelectedFileValue}
                        getOptionLabel={e =>
                            <div className="row">
                                <div className="col s8 left-align">
                                    {`${e.type} | ${e.name}`}
                                </div>
                                <div className="col s4 right-align">
                                    {bytesToSize(e.size)}
                                </div>
                            </div>
                        }
                        getOptionValue={e => e.name}
                        loadOptions={FileList}
                        onInputChange={handleSelectFileChange}
                        onChange={handleFilePick}
                    />
                </div>
            }
            <div className="row">
                <div className="col s6">
                    <div className="left-align">
                        {SelectedFileValue === null ? <></> :
                            <JsonTreeViewer data={SelectedFileValue || {}} title={'Selected File'}/>}
                    </div>
                </div>
                <div className="col s6">
                    <div className="left-align">
                        {SelectedFolderIdValue === null ? <></> :
                            <JsonTreeViewer data={SelectedFolderIdValue || {}} title={'Selected File Id'}/>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FilesDropdown;