import axios, {AxiosInstance} from 'axios';

class ApiService {
    constructor(base_url, access_token) {
        let defaultHeaders = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
        };
        let service: AxiosInstance = axios.create({
            headers: defaultHeaders,
            withCredentials: false
        });
        service.defaults.baseURL = base_url;
        service.defaults.headers = defaultHeaders;
        this.service = service;
    }

    handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
        console.warn(error);
        switch (error.response.status) {
            case 401:
                this.redirectTo(document, '/')
                break;
            case 404:
                this.redirectTo(document, '/404')
                break;
            default:
                this.redirectTo(document, '/500')
                break;
        }
        return Promise.reject(error)
    }

    redirectTo = (document, path) => {
        document.location = path
    }

    patch(path, payload, callback) {
        console.log('patch: ', path);
        return this.service.request({
            method: 'PATCH',
            url: path,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    get(path) {
        console.log('get: ', path);
        return this.service.request({
            method: 'GET',
            url: path,
        });
    }

    post(path, payload, callback) {
        console.log('post: ', path);
        return this.service.request({
            method: 'POST',
            url: path,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }
}

export default ApiService;