
const createFetchDataPromise = async (url, data) => {
    return await fetch(url, data);
};

const analyzePromiseResult = async (promise, url, data) => {
    console.log(promise);
    let result;
    if(promise.status === 200 || promise.status === 400 || promise.status === 404) {
        try{
            result = await promise.json();
        } catch(e){
            throw e;
        }
    } else{
        console.log(url, data);
        throw new Error(JSON.stringify(promise));
    }

    return result;
};

export async function getFetch(url, token) {
    const data = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            Accept: '*/*',
            Authorization: 'Bearer ' + token
        }
    };

    let promise = await createFetchDataPromise(url, data);
    return await analyzePromiseResult(promise, url, data);
}

export async function putFetch(url, request, token) {
    const data = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            Accept: '*/*',
            Authorization: 'Bearer ' + token
        },
        body: request
    };

    let promise = await createFetchDataPromise(url, data);
    return await analyzePromiseResult(promise, url, data);
}

export async function postFetch(url, request, token) {
    const data = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            Accept: '*/*',
            Authorization: 'Bearer ' + token
        },
        body: request
    };
    console.log(data);

    let promise = await createFetchDataPromise(url, data);
    return await analyzePromiseResult(promise, url, data);
}

export async function deleteFetch(url, request, token) {
    const data = {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            Accept: '*/*',
            Authorization: 'Bearer ' + token
        },
        body: request
    };
    console.log(data);

    let promise = await createFetchDataPromise(url, data);
    return await analyzePromiseResult(promise, url, data);
}