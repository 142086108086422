import React, {useState} from 'react';
import {useAuth} from "react-oidc-context";
import AuthService from "../../Services/AuthService";
import ApiService from "../../Services/ApiService";
import {ExternalProviders} from "../../Config/ExternalProviders";
import S3Login from "./S3Login";
import SFTPLogin from "./SFTPLogin";

export default function DD3Connections() {
    const auth = useAuth();
    const [Connections, setConnections] = useState(null);
    const [LoginButtons, setLoginButtons] = useState(null);

    if (Connections === null && auth?.user?.access_token !== undefined && auth?.user?.access_token !== null) {
        new AuthService(new ApiService(process.env.REACT_APP_SERVICES_BASE_URL_AUTH, auth.user.access_token))
            .getActiveConnections(auth.user.access_token).then(res => {
            updateActiveConnections(res.data['items']);
        });
    }

    function updateActiveConnections(connections) {
        const cons = [];
        connections.forEach(connection => {
                cons.push(
                    <div className="row center-align" key={`${connection['provider']}-row`}>
                        <div className="col s1"/>

                        <div className="col s3 left-align" key={`${connection['provider']}-provider`}>
                            <b className="left-align">{connection['provider']}</b>
                        </div>
                        <div className="col s4" key={`${connection['provider']}-user`}>
                            <p className="left-align">{connection['username']}</p>
                        </div>
                        <div className="col s4" key={`${connection['provider']}-unlink`}>
                            <button key={`${connection['provider']}-unlink-col-btn`}
                                    className="btn btn-small materialize-red unlink-button" onClick={async () => {
                                let api = new AuthService(new ApiService(process.env.REACT_APP_SERVICES_BASE_URL_AUTH, auth.user.access_token));
                                await api.deleteExternalLogin(auth.user.access_token, connection['id']);
                                window.location.reload();
                            }}>
                                <small
                                    className="center-align unlink-button-text">Delete {connection['provider']}</small>
                            </button>
                        </div>
                    </div>
                )
            }
        )

        setConnections(cons);
        const providers = connections.map(item => item['provider']);
        setLoginButtons(ExternalProviders.filter(p => p.displayName.toLowerCase() !== 'sftp' && p.provider.toLowerCase() !== 's3').filter(item => {
            return providers.indexOf(item.provider) === -1
        }).map((provider, index) => {
            return (
                <div className="row" key={index}>
                    <div className="col s12" key={index}>
                        <button key={`login-${index}`} className="btn btn-block-fw waves-effect waves-light"
                                onClick={async () => {
                                    let api = new AuthService(new ApiService(process.env.REACT_APP_SERVICES_BASE_URL_AUTH, auth.user.access_token));
                                    const response = await api.createDynamicLoginLink(auth.user.access_token, provider.scheme, window.location.origin + window.location.pathname, window.location.origin);
                                    window.location = response.data['authorizationUrl'];
                                }}>Login with {provider.provider}
                        </button>
                    </div>
                </div>)
        }));
    }

    if (auth.isLoading) {
        return (<></>);
    }
    if (auth.isAuthenticated) {
        return (
            <div className={"container"}>
                <div className="row">
                    <div className="valign-wrapper">
                        <h4>Connections</h4>
                    </div>
                    <hr/>
                    {Connections === null ? <div>Loading Connected Accounts...</div> : Connections}
                </div>
                <div className="row">
                    <div className="valign-wrapper">
                        <h4>Add Connections</h4>
                    </div>
                    <hr/>
                    {LoginButtons === null ? "" : LoginButtons}
                </div>
                <div className="row">
                    <div className="valign-wrapper">
                        <h4>S3</h4>
                    </div>
                    <S3Login user={auth.user}/>
                </div>
                <div className="row">
                    <div className="valign-wrapper">
                        <h4>SFTP</h4>
                    </div>
                    <SFTPLogin user={auth.user}/>
                </div>
            </div>);
    }
    return (<></>);
}
