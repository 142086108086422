// To parse this data:
//
//   import { SNSMessageConverter, SnsMessage } from "./file";
//
//   const snsMessage = SNSMessageConverter.toSnsMessage(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.


export interface Package {
    Version?: string;
    Uri?: string;
}

export interface Status {
    Version?: string;
    Message?: string;
    Detail?: string;
    StartDateTime?: Date;
    StatusCode?: number;
}


export interface Handler {
    Key?: string;
    Version?: string;
}

export interface Audience {
    Version?: string;
    Name?: string;
    Handler?: Handler;
}

export interface Topic {
    Name?: string;
    Region?: null;
    Version?: string;
    Audience?: Audience;
}

export interface Request {
    Version?: string;
    Package?: Package;
    Payload?: string;
}

export interface SnsMessage {
    Version?: string;
    CorrelationId?: string;
    TransactionId?: string;
    Topic?: Topic;
    Request?: Request;
    Status?: Status;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class SNSMessageConverter {
    static toSnsMessage(json: string): SnsMessage {
        return cast(JSON.parse(json), r("SnsMessage"));
    }

    static snsMessageToJson(value: SnsMessage): string {
        return JSON.stringify(uncast(value, r("SnsMessage")), null, 2);
    }
}

function invalidValue(typ: any, val: any, key: any = ''): never {
    if (key) {
        throw Error(`Invalid value for key "${key}". Expected type ${JSON.stringify(typ)} but got ${JSON.stringify(val)}`);
    }
    throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`,);
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.json] = {key: p.js, typ: p.typ});
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.js] = {key: p.json, typ: p.typ});
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val, key);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        const l = typs.length;
        for (let i = 0; i < l; i++) {
            const typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {
            }
        }
        return invalidValue(typs, val);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases, val);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue("array", val);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue("Date", val);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue("object", val);
        }
        const result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps, prop.key);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps, key);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val);
    }
    if (typ === false) return invalidValue(typ, val);
    while (typeof typ === "object" && typ.ref !== undefined) {
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems") ? transformArray(typ.arrayItems, val)
                : typ.hasOwnProperty("props") ? transformObject(getProps(typ), typ.additional, val)
                    : invalidValue(typ, val);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}


function u(...typs: any[]) {
    return {unionMembers: typs};
}

function o(props: any[], additional: any) {
    return {props, additional};
}

function r(name: string) {
    return {ref: name};
}

const typeMap: any = {
    "SnsMessage": o([
        {json: "Version", js: "Version", typ: u(undefined, "")},
        {json: "CorrelationId", js: "CorrelationId", typ: u(undefined, "")},
        {json: "TransactionId", js: "TransactionId", typ: u(undefined, "")},
        {json: "Topic", js: "Topic", typ: u(undefined, r("Topic"))},
        {json: "Request", js: "Request", typ: u(undefined, r("Request"))},
        {json: "Status", js: "Status", typ: u(undefined, r("Status"))},
    ], false),
    "Request": o([
        {json: "Version", js: "Version", typ: u(undefined, "")},
        {json: "Package", js: "Package", typ: u(undefined, r("Package"))},
        {json: "Payload", js: "Payload", typ: u(undefined, "")},
    ], false),
    "Package": o([
        {json: "Version", js: "Version", typ: u(undefined, "")},
        {json: "Uri", js: "Uri", typ: u(undefined, "")},
    ], false),
    "Status": o([
        {json: "Version", js: "Version", typ: u(undefined, "")},
        {json: "Message", js: "Message", typ: u(undefined, "")},
        {json: "Detail", js: "Detail", typ: u(undefined, "")},
        {json: "StartDateTime", js: "StartDateTime", typ: u(undefined, Date)},
        {json: "StatusCode", js: "StatusCode", typ: u(undefined, 0)},
    ], false),
    "Topic": o([
        {json: "Name", js: "Name", typ: u(undefined, "")},
        {json: "Region", js: "Region", typ: u(undefined, null)},
        {json: "Version", js: "Version", typ: u(undefined, "")},
        {json: "Audience", js: "Audience", typ: u(undefined, r("Audience"))},
    ], false),
    "Audience": o([
        {json: "Version", js: "Version", typ: u(undefined, "")},
        {json: "Name", js: "Name", typ: u(undefined, "")},
        {json: "Handler", js: "Handler", typ: u(undefined, r("Handler"))},
    ], false),
    "Handler": o([
        {json: "Key", js: "Key", typ: u(undefined, "")},
        {json: "Version", js: "Version", typ: u(undefined, "")},
    ], false),
};
