import React, {useState} from 'react';
import AWS from 'aws-sdk/dist/aws-sdk-react-native';
import {Button} from 'react-materialize';
import EventJson from '../Models/EventJson.json'
import {SendPayloadModel} from '../Models/SendPayloadModel';
import type {SnsMessage} from "../Models/SnsMessage";
import {SNSMessageConverter} from "../Models/SnsMessage";
import JsonTreeViewer from "../Auth/JsonTreeViewer";
import UUIDGenerator from "../Services/UUIDGenerator";
import PollStatus from "./PollStatus";


const PublishEvent = ({AudienceName, CSPName, preSignedUrl, parentFolderId, CongaOrgId, FileName}) => {

    const [MessageModel, setMessageModel] = useState('');
    const [CorrelationId, setCorrelationId] = useState(null);

    const buildMessage = () => {
        let message: SnsMessage = SNSMessageConverter.toSnsMessage(JSON.stringify(EventJson));
        message.CorrelationId = `${UUIDGenerator.generate()}-dd3`;
        message.TransactionId = `${UUIDGenerator.generate()}-dd3`;
        message.Topic.Name = process.env.REACT_APP_AWS_DELIVERY_TOPIC;
        message.Topic.TopicArn = process.env.REACT_APP_AWS_DELIVERY_TOPIC;
        message.Topic.Audience.Handler.Key = "send";
        message.Topic.Audience.Name = AudienceName;
        message.Request.Payload = JSON.stringify(new SendPayloadModel(preSignedUrl, FileName, parentFolderId, CongaOrgId));
        return message;
    }

    function publish() {
        setCorrelationId(null);
        AWS.config.update({region: process.env.REACT_APP_AWS_REGION});
        const topicArn = process.env.REACT_APP_AWS_DELIVERY_TOPIC;
        let message = buildMessage();
        console.log(`Topic: `, topicArn);
        console.log(`AudienceName: `, AudienceName);
        console.log(`Message: `, message);
        setCorrelationId(message.CorrelationId);
        setMessageModel(message);
        const params = {
            Message: JSON.stringify(message),
            TopicArn: topicArn
        };
        console.log("Sending SNS Message: ", message)
        const snsParams = {
            apiVersion: '2010-03-31',
            region: process.env.REACT_APP_AWS_REGION,
            forcePathStyle: true
        }
        if (process.env.REACT_APP_AWS_ENDPOINT_URL !== null && process.env.REACT_APP_AWS_ENDPOINT_URL.toString().length > 0 && process.env.REACT_APP_AWS_ACCESS_KEY_ID === 'mock') {
            snsParams.endpoint = process.env.REACT_APP_AWS_ENDPOINT_URL;
            snsParams.useHttp = true;
        }
        const sns = new AWS.SNS(
            snsParams
        );
        sns.publish(params).promise().then(res => {
            console.log('publish', CSPName, res);
        }).catch(err => {
            console.error('error publishing', err)
        })
    }

    return (
        <div>
            <Button
                node="button"
                id="send-grab-button"
                waves="light"
                onClick={publish}
            >
                Publish Event
            </Button>
            <div className="left-align">
                <JsonTreeViewer data={MessageModel || {}} title={`${CSPName} -> ${AudienceName}`}/>
            </div>
            <PollStatus correlationId={CorrelationId}/>
        </div>
    )
}

export default PublishEvent;