import {React, useState} from 'react';
import Input from '../../Components/Input';
import { Button } from 'react-materialize';
import { postFetch, deleteFetch } from '../../Services/HttpUtil';
import CustomAccordion from '../../Components/CustomAccordion';
import {useAuth} from "react-oidc-context";

const PandaServices = () => {
    const auth = useAuth();

    const [ preSignedUrl, setPreSignedUrl ] = useState('');
    const [ expirationInSeconds, ] = useState(5000);
    const [ lookupUrl, setLookupUrl ] = useState('');
    const [ generatedUrlForDelete, setGeneratedUrlForDelete ] = useState('');
    const [ fileContents, setFileContents ] = useState(null);

    const [ isCreating, setIsCreating ] = useState(false);
    const [ isGetting, setIsGetting ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    const [ createSuccessful, setCreateSuccessful ] = useState(false);
    const [ createError, setCreateError ] = useState(false);

    const [ createMessage, setCreateMessage ] = useState('');
    const [ deleteMessage, setDeleteMessage ] = useState('');

    const [ expanded, setExpanded ] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const createPresignedUrl = async () => {
        setCreateMessage('');
        setCreateSuccessful(false);
        setCreateError(false);
        setIsCreating(true);

        try {
            const params = {preSignedUrl, expirationInSeconds}
            const { message, status } = await postFetch(`${process.env.REACT_APP_SERVICES_BASE_URL_PRESIGNEDPANDA}/v1/PreSignedService`, JSON.stringify(params), auth.user.access_token);
            console.log( { message, status });
            setCreateMessage(message);
            setCreateSuccessful(true);
        } catch(e) {
            setCreateMessage("Unable to generate URL");
            setCreateSuccessful(false);
        }
        setIsCreating(false);
    }

    const getPresignedUrl = async () => {
        setFileContents('');
        setIsGetting(true);

        const getResponse = await fetch(lookupUrl);
        console.log(getResponse);
        setIsGetting(false);
        const sampleContents = {
            ok: getResponse.ok,
            redirected: getResponse.redirected,
            status: getResponse.status,
            statusText: getResponse.statusText,
            type: getResponse.type,
            url: getResponse.url,
            headers: [...getResponse.headers]
        };
        setFileContents(JSON.stringify(sampleContents));
    }

    const deletePresignedUrl = async () => {
        setIsDeleting(true);
        try {
            const deleteResponse = await deleteFetch(`${process.env.REACT_APP_SERVICES_BASE_URL_PRESIGNEDPANDA}/v1/PreSignedService/?generatedUrl=${generatedUrlForDelete}`, '', auth.user.access_token);
            console.log(deleteResponse);
            setDeleteMessage(deleteResponse.message);
        } catch(e) {
            setCreateMessage("Unable to delete URL");
        }
        setIsDeleting(false);
    }

    return (
        <div className="form">
            <p>Presigned Panda Services</p>

            <CustomAccordion
                accordionText="Create PreSigned Url"
                accordionId="createpresignedurl"
                expanded={expanded}
                handleChange={handleChange}
                fontSize={16}
                accordionBody={
                    <>
                        <Input label="PreSigned Url" setInput={setPreSignedUrl} />

                        <Button
                            node="button"
                            waves="light"
                            onClick={createPresignedUrl}
                            style={{width: 'auto'}}
                        >
                            Create
                        </Button>

                        {isCreating &&
                            <div className="form" style={{marginTop: '10px', width: '50%'}}>
                                <div class="">Creating PreSigned Url...</div>
                                <progress id="file" style={{width: "0", minWidth: "100%", }} />
                            </div>
                        }

                        {createSuccessful &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">Presigned Url Generated</div>
                                <div class="">{createMessage}</div>
                            </div>
                        }

                        {createError &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">{createMessage}</div>
                            </div>
                        }
                    </>
                }
            ></CustomAccordion>

            <CustomAccordion
                accordionText="Get PreSigned Url"
                accordionId="getpresignedurl"
                expanded={expanded}
                handleChange={handleChange}
                fontSize={16}
                accordionBody={
                    <>
                        <Input label="Generated URL" setInput={setLookupUrl} />

                        <Button
                            node="button"
                            waves="light"
                            onClick={getPresignedUrl}
                            style={{width: 'auto'}}
                        >
                            Get
                        </Button>

                        {isGetting &&
                            <div className="form" style={{marginTop: '10px', width: '50%'}}>
                                <div class="">Getting PreSigned Url By Lookup Id...</div>
                                <progress id="file" style={{width: "0", minWidth: "100%", }} />
                            </div>
                        }

                        {!isGetting && fileContents != null &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">{fileContents}</div>
                            </div>
                        }
                    </>
                }
            ></CustomAccordion>

            <CustomAccordion
                accordionText="Delete PreSigned Url"
                accordionId="deletepresignedurl"
                expanded={expanded}
                handleChange={handleChange}
                fontSize={16}
                accordionBody={
                    <>
                        <Input label="Generated Url" setInput={setGeneratedUrlForDelete} />

                        <Button
                            node="button"
                            waves="light"
                            onClick={deletePresignedUrl}
                            style={{width: 'auto'}}
                        >
                            Delete
                        </Button>

                        {isDeleting &&
                            <div className="form" style={{marginTop: '10px', width: '50%'}}>
                                <div class="">Deleting PreSigned Url...</div>
                                <progress id="file" style={{width: "0", minWidth: "100%", }} />
                            </div>
                        }

                        {!isDeleting && deleteMessage &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">{deleteMessage}</div>
                            </div>
                        }
                    </>
                }
            ></CustomAccordion>
        </div>
    );
}

export default PandaServices;