import React, {useEffect, useState} from "react";
import GetPresignedUrl from "../Components/GetPresignedUrl";
import PublishEvent from "../Components/PublishEvent";
import CopyTextButton from "../Components/CopyTextButton";
import CSPDropdown from "../Components/CSPDropdown";
import {TextInput} from "react-materialize";
import {ExternalProvider} from "../Config/ExternalProvider";
import {ExternalProviders} from "../Config/ExternalProviders";
import ParentFolderIdDropdown from "../Components/ParentFolderIdDropdown";

const SendPage = ({user}) => {
    const [CSPName, setCSPName] = useState(null);
    const [CongaToken, setCongaToken] = useState(null);
    const [ParentFolderId, setParentFolderId] = useState(null);
    const [PreSignedUrl, setPreSignUrl] = useState(null);
    const [FileName, setFileName] = useState(null);
    const [AudienceName, setAudienceName] = useState(null);


    useEffect(() => {
        setCongaToken(user.access_token);
        console.log(`updating audience for CSP: ${CSPName}`);
        if (CSPName !== null && CSPName.toString().length > 0) {
            let externalProvider: ExternalProvider = ExternalProviders.filter(item => item.filePickerProvider.toLowerCase() === CSPName.toString().toLowerCase() || item.scheme === CSPName.toString().toLowerCase())[0];
            setAudienceName(externalProvider.audience)
            console.log(`updated audience for CSP: ${CSPName} audience: ${externalProvider.audience}`);
        }

    }, [AudienceName, CSPName, user.access_token]);

    function resetInput() {
        setCSPName(null);
        setAudienceName(null);
        setPreSignUrl(null);
        setParentFolderId(null);
        setFileName(null);
    }

    if (CSPName === null) {
        return (
            <div className="form">
                <div className="row">
                    <CSPDropdown setCSP={setCSPName} reset={resetInput}/>
                </div>
            </div>
        );
    }
    return (
        <div className="form">
            <div className="row">
                <CSPDropdown setCSP={setCSPName} reset={resetInput}/>
            </div>
            <div className="row">
                <div className="card">
                    <ParentFolderIdDropdown currentCsp={CSPName} user={user} setParentFolderId={setParentFolderId}/>
                </div>
            </div>
            <div className="row card">
                <div className="col s12">
                    <TextInput label={'Override File Name'} placeholder={'File Name'} onChange={(ev => {
                        setFileName(ev.target.value)
                    })}
                               defaultValue={FileName}/>
                </div>
            </div>
            {CSPName.toString().length < 1 ? <div className="row card"/> : <div className="row card">
                <GetPresignedUrl
                    setPresignUrl={setPreSignUrl}
                    prefix={CSPName}
                />
            </div>}
            <div className="row">
                {[
                    {
                        'label': 'Presigned URL',
                        'value': PreSignedUrl
                    },
                    {
                        'label': 'File Name',
                        'value': FileName
                    },
                    {
                        'label': 'Parent Folder Id',
                        'value': ParentFolderId
                    },


                ].map(item => {
                    return (
                        <div key={item['label']} className="row">
                            <div className="col s2"><p className="left-align">{item['label']}</p></div>
                            <div className="col s2">
                                <CopyTextButton copyText={'Copy ' + item['label']}
                                                copyValue={item['value']}/>
                            </div>
                            <div className="col s8 left-align">
                                {item['value']}
                            </div>
                        </div>

                    )
                })}
            </div>

            {(CSPName === null ?? ParentFolderId === null ?? PreSignedUrl === null) ? <></> :
                <PublishEvent
                    AudienceName={AudienceName}
                    preSignedUrl={PreSignedUrl}
                    CSPName={CSPName}
                    parentFolderId={ParentFolderId}
                    CongaToken={CongaToken}
                    FileName={FileName}
                    CongaOrgId={user.profile['c:org:id']}
                />}
        </div>
    );
};

export default SendPage;