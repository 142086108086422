import {React, useState} from 'react';
import { Button } from 'react-materialize';
import { postFetch } from '../../Services/HttpUtil';
import { buildCrateEvent, publishCrateEvent } from '../../Services/CrateEventService';
import CustomAccordion from '../../Components/CustomAccordion';
import AddFile from './AddFile';
import {useAuth} from "react-oidc-context";

const FileServices = () => {
    const auth = useAuth();
    const [ retrievalQueryParams, setRetrievalQueryParams ] = useState('');
    const [ deleteQueryParams, setDeleteQueryParams ] = useState('');
    const [ undeleteQueryParams, setUndeleteQueryParams ] = useState('');

    const [ isRetrieving, setIsRetrieving ] = useState(false);
    const [ retrieveSuccessful, setRetrieveSuccessful ] = useState(false);
    const [ retrieveError, setRetrieveError ] = useState(false);
    const [ retrieveLocation, setRetrieveLocation ] = useState('');
    const [ retrieveErrorMessage, setRetrieveErrorMessage ] = useState('');

    const [ isDeleting, setIsDeleting ] = useState(false);
    const [ deleteSuccessful, setDeleteSuccessful ] = useState(false);
    const [ deleteSuccessMessage, setDeleteSuccessMessage ] = useState('');
    const [ deleteError, setDeleteError ] = useState(false);
    const [ deleteErrorMessage, setDeleteErrorMessage ] = useState('');

    const [ isUndeleting, setIsUndeleting ] = useState(false);
    const [ undeleteSuccessful, setUndeleteSuccessful ] = useState(false);
    const [ undeleteSuccessMessage, setUndeleteSuccessMessage ] = useState('');
    const [ undeleteError, setUndeleteError ] = useState(false);
    const [ undeleteErrorMessage, setUndeleteErrorMessage ] = useState('');

    const [ expanded, setExpanded ] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const retrieveFile = async () => {
        setIsRetrieving(true);
        setRetrieveSuccessful(false);
        setRetrieveError(false);

        try {
            const retrievalResponse = await postFetch(`${process.env.REACT_APP_SERVICES_BASE_URL_CRATECRAB}/retrieve-file?fileId=${retrievalQueryParams}`, {}, auth.user.access_token);
            console.log(retrievalResponse);
            setRetrieveError(!retrievalResponse.isSuccessful);
            setRetrieveSuccessful(retrievalResponse.isSuccessful);
            retrievalResponse.isSuccessful && setRetrieveLocation(retrievalResponse.message);
        } catch(e) {
            setRetrieveSuccessful(false);
            setRetrieveError(true);
            setRetrieveErrorMessage("Unable to retrieve file");
        }
        setIsRetrieving(false);
    }

    const deleteFileMetadata = async () => {
        setIsDeleting(true);
        setDeleteSuccessful(false);
        setDeleteError(false);

        const eventMessage = buildCrateEvent(deleteQueryParams, 'DeleteFile');
        publishCrateEvent(eventMessage);

        try {
            const deleteResponse = await postFetch(`${process.env.REACT_APP_SERVICES_BASE_URL_CRATECRAB}/delete-files`, deleteQueryParams || "[]", auth.user.access_token);
            console.log(deleteResponse);
            setDeleteError(!deleteResponse.isSuccessful);
            setDeleteSuccessful(deleteResponse.isSuccessful);
            deleteResponse.isSuccessful ? setDeleteSuccessMessage(deleteResponse.message) : setDeleteErrorMessage(deleteResponse.message);
        } catch(e) {
            setDeleteSuccessful(false);
            setDeleteError(true);
            setDeleteErrorMessage("Unable to delete files");
        }
        setIsDeleting(false);
    }

    const undeleteFileMetadata = async () => {
        setIsUndeleting(true);
        setUndeleteSuccessful(false);
        setUndeleteError(false);
        try {
            const undeleteResponse = await postFetch(`${process.env.REACT_APP_SERVICES_BASE_URL_CRATECRAB}/undelete-files`, undeleteQueryParams || "[]", auth.user.access_token);
            console.log(undeleteResponse);
            setUndeleteError(!undeleteResponse.isSuccessful);
            setUndeleteSuccessful(undeleteResponse.isSuccessful);
            undeleteResponse.isSuccessful ? setUndeleteSuccessMessage(undeleteResponse.message) : setUndeleteErrorMessage(undeleteResponse.message);
        } catch(e) {
            setUndeleteSuccessful(false);
            setUndeleteError(true);
            setUndeleteErrorMessage("Unable to undelete files");
        }
        setIsUndeleting(false);
    }

    return (
        <>
            <CustomAccordion
                accordionText="Add File(s)"
                accordionId="addfile"
                expanded={expanded}
                handleChange={handleChange}
                fontSize={16}
                accordionBody={<AddFile />}
            ></CustomAccordion>

            <CustomAccordion
                accordionText="Retrieve File"
                accordionId="retrievefile"
                expanded={expanded}
                handleChange={handleChange}
                fontSize={16}
                accordionBody={
                    <>
                        <div className="input-field col s12">
                            <textarea
                                id="retrievefile-query-params"
                                className="materialize-textarea"
                                value={retrievalQueryParams}
                                onChange={e => setRetrievalQueryParams(e.target.value)}
                            ></textarea>
                            <label htmlFor="retrievefile-query-params">Single file-id</label>
                        </div>

                        <Button
                            node="button"
                            waves="light"
                            onClick={retrieveFile}
                            style={{width: 'auto'}}
                        >
                            Starting file-retrieval
                        </Button>

                        {isRetrieving &&
                            <div className="form" style={{marginTop: '10px', width: '50%'}}>
                                <div class="">Retrieving file...</div>
                                <progress id="file" style={{width: "0", minWidth: "100%", }} />
                            </div>
                        }

                        {retrieveSuccessful &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">File successfully retrieved. File can be downloaded from: <a href={retrieveLocation}>here</a>. (May be a slight delay before the file is ready)</div>
                            </div>
                        }

                        {retrieveError &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">{retrieveErrorMessage}</div>
                            </div>
                        }
                    </>
                }
            ></CustomAccordion>

            <CustomAccordion
                accordionText="Delete File(s) using Metadata"
                accordionId="deletefilemetadata"
                expanded={expanded}
                handleChange={handleChange}
                fontSize={16}
                accordionBody={
                    <>
                        <div className="s12">
                            <p style={{textAlign: "left"}}><em>[&#123;"key": "fileId|orgId|fileName|userId", "value": "..."&#125;, ...]</em></p>
                        </div>
                        <div className="input-field col s12">
                            <textarea
                                id="deletefilemetadata-query-params"
                                className="materialize-textarea"
                                value={deleteQueryParams}
                                onChange={e => setDeleteQueryParams(e.target.value)}
                            ></textarea>
                            <label htmlFor="deletefilemetadata-query-params">E.g. [&#123;"key": "fileId", "value": "..."&#125;]</label>
                        </div>

                        <Button
                            node="button"
                            waves="light"
                            onClick={deleteFileMetadata}
                            style={{width: 'auto'}}
                        >
                            Mark Files For Delete
                        </Button>

                        {isDeleting &&
                            <div className="form" style={{marginTop: '10px', width: '50%'}}>
                                <div class="">Marking File as Deleted...</div>
                                <progress id="file" style={{width: "0", minWidth: "100%", }} />
                            </div>
                        }

                        {deleteSuccessful &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">{deleteSuccessMessage}</div>
                            </div>
                        }

                        {deleteError &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">{deleteErrorMessage}</div>
                            </div>
                        }
                    </>
                }
            ></CustomAccordion>

            <CustomAccordion
                accordionText="Undelete File(s) using Metadata"
                accordionId="undeletefilemetadata"
                expanded={expanded}
                handleChange={handleChange}
                fontSize={16}
                accordionBody={
                    <>
                        <div className="s12">
                            <p style={{textAlign: "left"}}><em>[&#123;"key": "fileId|orgId|fileName|userId", "value": "..."&#125;, ...]</em></p>
                        </div>
                        <div className="input-field col s12">
                            <textarea
                                id="undeletefilemetadata-query-params"
                                className="materialize-textarea"
                                value={undeleteQueryParams}
                                onChange={e => setUndeleteQueryParams(e.target.value)}
                            ></textarea>
                            <label htmlFor="undeletefilemetadata-query-params">E.g. [&#123;"key": "fileId", "value": "..."&#125;]</label>
                        </div>

                        <Button
                            node="button"
                            waves="light"
                            onClick={undeleteFileMetadata}
                            style={{width: 'auto'}}
                        >
                            Undelete File Metadata
                        </Button>

                        {isUndeleting &&
                            <div className="form" style={{marginTop: '10px', width: '50%'}}>
                                <div class="">Undeleting File(s)...</div>
                                <progress id="file" style={{width: "0", minWidth: "100%", }} />
                            </div>
                        }

                        {undeleteSuccessful &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">{undeleteSuccessMessage}</div>
                            </div>
                        }

                        {undeleteError &&
                            <div className="form" style={{marginTop: '10px'}}>
                                <div class="">{undeleteErrorMessage}</div>
                            </div>
                        }
                    </>
                }
            ></CustomAccordion>
        </>
    );
}

export default FileServices;