import React, {useEffect, useState} from "react";
import ApiService from "../../Services/ApiService";
import AnimalService from "../../Services/AnimalService";
import Input from "../../Components/Input";


const S3Login = ({user}) => {

    const [Connection, setConnection] = useState(null);
    const [AccessKeyId, setAccessKeyId] = useState(null);
    const [SecretAccessKey, setSecretAccessKey] = useState(null);


    useEffect(() => {
        const animal = new AnimalService(new ApiService(process.env.REACT_APP_SERVICES_BASE_URL_S3SQUID, user.access_token));
        animal.getActiveConnections().then(connectionsRes => {
            if (connectionsRes.status === 200){
                const connection = connectionsRes.data['items'][0];
                if (connection['active']) {
                    setConnection(connection)
                }
            }
        });
    }, [user.access_token])

    function saveCredentials() {
        new AnimalService(new ApiService(process.env.REACT_APP_SERVICES_BASE_URL_S3SQUID, user.access_token))
            .saveConnection(AccessKeyId, SecretAccessKey).then(res => {
            console.log('saved', res)
        })
    }

    return (
        <div>
            <p>
                {Connection !== null ? Connection['username'] : "Not Connected"}
            </p>
            <div className="row">
                <div className="col s3">
                    <Input label={"Access Key Id"} setInput={setAccessKeyId}/>
                </div>
                <div className="col s3">
                    <Input label={"Secret Access Key"} setInput={setSecretAccessKey}/>
                </div>
                <div className="col s12">
                    <button disabled={AccessKeyId === null || SecretAccessKey === null} className="btn"
                            onClick={saveCredentials}>Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default S3Login;