import AWS from "aws-sdk/dist/aws-sdk-react-native";
import CrateEventJson from "../Models/CrateEventJson.json";

export const buildCrateEvent = (payload, handlerKey) => {
    const createUUID = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    var messageJson = CrateEventJson;
    messageJson.Request.Payload = payload;
    messageJson.Topic.Audience.Handler.Key = handlerKey;
    messageJson.Topic.Region = process.env.REACT_APP_AWS_REGION;
    messageJson.CorrelationId = createUUID();
    messageJson.TransactionId = createUUID();
    //messageJson.Topic.Name = "I think needs to change";
    console.log("audience name is" + messageJson.Topic.Audience.Name);

    var sns_data = JSON.stringify(messageJson);
    console.log("The SNS data: " + sns_data);
    return sns_data;
};

export const publishCrateEvent = (eventMessage) => {
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    });

    const sns = new AWS.SNS({
        region: process.env.REACT_APP_AWS_REGION,
        endpoint: process.env.REACT_APP_AWS_ENDPOINT_URL,
        forcePathStyle: true
    });
    console.log(sns);

    var params = {
      Message: eventMessage,
      TopicArn: 'arn:aws:sns:us-west-2:808783464244:docgen-dev-cratecrab-topic'
    };

    sns.publish(params, function (err, data) {
        if(err){
            console.log(err, err.stack);
        } else{
            console.log(data);
        }
    });
  };