import React, { useState } from "react";

// Credit https://www.freecodecamp.org/news/build-accordion-menu-in-react-without-external-libraries/
// accordionId, expanded, handleChange, fontSize}

const CustomAccordion = ({ accordionText, accordionBody, accordionId }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <li className="accordion-item" id={accordionId + '-header'}>
      <div className="accordion-toggle" onClick={() => setIsActive(!isActive)}>
        <h3>{accordionText}</h3><span>{isActive ? "-" : "+"}</span>
      </div>
      {isActive && <div className="accordion-content">{accordionBody}</div>}
    </li>
  );
};

export default CustomAccordion;