import React, {useEffect, useState} from "react";
import ApiService from "../../Services/ApiService";
import AnimalService from "../../Services/AnimalService";
import Input from "../../Components/Input";


const SFTPLogin = ({user}) => {

    const [Connection, setConnection] = useState(null);
    const [UserName, setUserName] = useState(null);
    const [Password, setPassword] = useState(null);
    const [Host, setHost] = useState(null);
    const [Port, setPort] = useState(null);


    useEffect(() => {
        const animal = new AnimalService(new ApiService(process.env.REACT_APP_SERVICES_BASE_URL_SFTPSPIDER, user.access_token));
        animal.getActiveConnections().then(connectionsRes => {
            if (connectionsRes.status === 200){
                const connection = connectionsRes.data['items'][0];
                if (connection['active']) {
                    setConnection(connection)
                }
            }
        });
    }, [user.access_token])

    function saveCredentials() {
        new AnimalService(new ApiService(process.env.REACT_APP_SERVICES_BASE_URL_SFTPSPIDER, user.access_token))
            .saveSftpConnection(UserName, Password, Host, parseInt(Port)).then(res => {
            console.log('saved', res)
        })
    }

    return (
        <div>
            <p>
                {Connection !== null ? Connection['username'] : "Not Connected"}
            </p>
            <div className="row">
                <div className="col s3">
                    <Input label={"User"} setInput={setUserName}/>
                </div>
                <div className="col s3">
                    <Input label={"Password"} setInput={setPassword}/>
                </div>
                <div className="col s3">
                    <Input label={"Host"} setInput={setHost}/>
                </div>
                <div className="col s3">
                    <Input label={"Port"} setInput={setPort}/>
                </div>
                <div className="col s12">
                    <button disabled={UserName === null || Password === null} className="btn"
                            onClick={saveCredentials}>Save
                    </button>
                </div>

            </div>
        </div>
    );
}

export default SFTPLogin;