import React from 'react';
import 'materialize-css';
import '../css/loading.css';

const Loading = ({loadingText = 'Loading...'}) => {
    return (
        <div className="center-align">
            <p>{loadingText}</p>
            <div className="spinner-container">
                <div className="loading-spinner"/>
            </div>
        </div>
    );

}


export default Loading;